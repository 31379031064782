<template>
  <div class="ScriptsTable">
        <b-card class="mb-1">    
            <b-card-header class="h6 mb-1 psm-header">
              <b-row>
                <b-col sm="3">
                  <span @click="toggleVisibilityCardResult" class="psm-cursor-pointer">
                    <b-icon-caret-down-fill v-if="!elements.cardResultVisible" class="h6 mb-1" variant="light"/>
                    <b-icon-caret-up-fill   v-if="elements.cardResultVisible" class="h6 mb-1" variant="light"/>
                  </span>
                  &nbsp;{{cardTitle}} ( {{ result.tableData.length }} scripts)
                </b-col>
                <b-col sm="2"></b-col>
                <b-col sm="2">
                  <div class="float-sm-right">
                    <b-dropdown id="dropdown-form" text="Filter" ref="dropdownFilter" size="sm">
                      <b-dropdown-form>
                        <b-form-input size="sm" style='width:150px' v-model="result.filterValue" placeholder="Enter filter value..." @submit.stop.prevent></b-form-input>
                        <b-button size="sm" class='mt-2' type="button" v-on:click="applyFilter" title='Apply the filter.'>Apply</b-button>
                        <b-button size="sm" class='float-sm-right ml-2 mt-2' variant="danger" type="button" v-on:click="resetFilter" title='Reset the filter.'>Reset</b-button>
                      </b-dropdown-form>
                    </b-dropdown>
                  </div>
                </b-col>
                <b-col sm="5">
                  <p class="float-sm-right psm-cursor-pointer" v-if="(nrOfScripts % 100 === 0 && nrOfScripts !== 0)"
                    @click="search(false)">More scripts....
                  </p>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-text v-if="elements.cardResultVisible" >
              <b-row>
                <b-col sm="12">
                  <ve-table :columns="result.columns" :table-data="result.tableData" :sort-option="sortOption"
                    :cell-style-option="cellStyleOption" :event-custom-option="result.selectScriptEventOption"
                    :expand-option="result.expandRow" row-key-field-name="id"
                  ></ve-table> 
                </b-col>
              </b-row>
            </b-card-text>
        </b-card>
  </div>
</template>

<script>
  import CopyClipboard from './CopyClipboard.vue';
  export default {
    data() {
      return {
         tabIndex:0,
         errors: [],
         elements:{
           cardResultVisible: true
         },
        sortOption: {
          sortChange: (params) => {
            this.sortChange(params);
          },
        },
         result:{
          filterValue: '',
          selectScriptEventOption: {
            // eslint-disable-next-line no-unused-vars            
            bodyCellEvents: ({ row,column,rowIndx }) => {
              return {
              // eslint-disable-next-line no-unused-vars            
                click: (event) => {
                  if (column.field === 'detail')
                  {
                    this.elements.cardResultVisible = false;
                    this.$emit('selectScript', row['key']);
                  }
                },
               };
            },
          },
          expandRow:{
            // eslint-disable-next-line no-unused-vars            
            render: ({ row, column, rowIndex }, h) => {
              return (
                <p class='psm-font-small'>
                  {row.description}
                </p>
              );            
            } 
          },   
          columns: [
            { field: "", key: "a0", type: "expand", title: "", width: '50px', align: "center", },            
            { field: "", key: "a1", title: "", width: '10px', align: "center",
              // eslint-disable-next-line no-unused-vars            
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                var val = row['key'];
                return <CopyClipboard valueToCopy={val} title="Copy script key to clipboard."/>;
              },
            },
            { field: "detail", key: "a2", title: "Details", width: '10px', align: "center",
              // eslint-disable-next-line no-unused-vars            
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return <font-awesome-icon icon="folder-open" class="psm-folder-open-icon" title="Open script details." />
              },
            },
            { field: "key", key: "b", title: "Key", align: "left", width: "30%",
              // eslint-disable-next-line no-unused-vars
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                var key = row[column.field];
                return (
                  <div title={key}>{key}</div>
                  );
              }
            },
            { field: "name", key: "c", title: "Name", align: "left", sortBy: "", width: "35%"  },
            { field: "version", key: "b2", title: "Version", align: "left", sortBy: "", width: "15%",
              // eslint-disable-next-line no-unused-vars
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                var vs = this.formatVersion(row[column.field]);
                return ( <div>{vs}</div> );
              }
            },
            { field: "dated", key: "f", title: "Dated", align:"left", sortBy: "", width: "20%",
              // eslint-disable-next-line no-unused-vars
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                var dt = this.formatDate(row[column.field]);
                return ( <div>{dt}</div> );
              }
            },
          ],
          tableData:[],
          tableDataInitial:[],
         }         
       }
    },
    created(){
        this.loadScripts();
    },
    methods: {
      toggleVisibilityCardResult(){
        this.elements.cardResultVisible = !this.elements.cardResultVisible;
      },
      applyFilter(){
        if ( this.result.tableData === []) this.result.tableData = [...this.result.tableDataInitial];
        this.result.tableData = this.result.tableData.filter(row =>{return (row.key.includes(this.result.filterValue) || row.name.includes(this.result.filterValue)||
                                                                        row.dated.includes(this.result.filterValue)|| row.description.includes(this.result.filterValue))});
        this.$refs.dropdownFilter.hide(true);
      }, 
      resetFilter(){
        this.result.tableData = [...this.result.tableDataInitial];
        this.result.filterValue = '';
        this.$refs.dropdownFilter.hide(true);
      }, 
      loadScripts(){
        var parm =  { Value : this.$store.state.account.login };
        this.$restPost('/backoffice/script/getbycustomer', parm, this.callbackLoadScripts, 'Error getting scripts.');
      },
      callbackLoadScripts(response){
          console.log(response);
          this.result.tableData = response.data.Data;
          this.result.tableDataInitial = [...this.result.tableData];
      },
      delete(key){
        // From both array's.
        const findKey = (element) => element.key == key;
        var index = this.result.tableData.findIndex(findKey);
        if (index > -1) this.result.tableData.splice(index,1);
        index = this.result.tableDataInitial.findIndex(findKey);
        if (index > -1) this.result.tableDataInitial.splice(index,1);
      },
     //#region Format
      formatDate(date){
        return ( date.substring(0,4)+"-"+date.substring(5,7)+"-"+date.substring(8,10)+" "+date.substring(11,13)+":"+date.substring(14,16) );
      },
      formatVersion(version){
          if (version < 10 ) return '00'+version;
          if (version < 100 ) return '0'+version;
          return version;
      },
      //#endregion 
      //#region Sort
      sortChange(params){
        this.result.tableData.sort((a, b) => {
          if (params.name) {
            if (params.name === "asc") {
              if (a.name > b.name ) return -1;
              if (a.name < b.name ) return 1;
              return 0;
            }
            if (params.name === "desc") {
              if (a.name > b.name ) return 1;
              if (a.name < b.name ) return -1;
              return 0;
            }
          }
          if (params.dated) {
            if (params.dated === "asc") {
              if (a.dated > b.dated ) return -1;
              if (a.dated < b.dated ) return 1;
              return 0;
            }
            if (params.dated === "desc") {
              if (a.dated > b.dated ) return 1;
              if (a.dated < b.dated ) return -1;
              return 0;
            }
          }
          if (params.version) {
            if (params.version === "asc") {
              if (a.version > b.version ) return -1;
              if (a.version < b.version ) return 1;
              return 0;
            }
            if (params.version === "desc") {
              if (a.version > b.version ) return 1;
              if (a.version < b.version ) return -1;
              return 0;
            }
          }
        })
      },
      //#endregion     
    },
    components: {
    },
    props:['cardTitle'],    
  }
</script>
<style scoped>

</style>