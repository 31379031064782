<template>
        <font-awesome-icon icon="clipboard" @click="copyToClipboard" 
            class="psm-clipboard-icon" :title="title" />
  
</template>

<script>
export default {
    data() {
      return {

      }
    },
    props: [ 'valueToCopy', 'title' ],    
    methods: {
        copyToClipboard(){
            this.$clipboard(this.valueToCopy);
        }
    }
}
</script>