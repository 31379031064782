<template>
  <div class="Contact">
    <b-container>
        <br/> 
        <b-card>
            <b-card-header class="h5 mb-2 psm-header">Register</b-card-header>
            <b-card-text>
              <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul>
                    <b-alert variant="danger" show>
                      <li v-for="error in errors" v-bind:key="error">
                        {{ error }}
                      </li>
                    </b-alert>
                  </ul>
              </p>
                Fill in the form below and press Submit. After registering you will receive an email to confirm your registration.
                <b-form @submit="onSubmit" @reset="onReset">
                    <b-row class="mt-2">
                        <b-col sm="2">
                        <label for="name">Your name:</label>
                        </b-col>                        
                        <b-col sm="4">
                        <b-form-input
                            id="name"
                            placeholder="Type your name here...."
                            v-model="register.name"
                        ></b-form-input>
                        </b-col>
                        <b-col sm="2">
                        <label for="login">Choose your login:</label>
                        </b-col>                        
                        <b-col sm="4">
                        <b-form-input
                            id="login"
                            placeholder="Type your login here...."
                            v-model="register.login"
                        ></b-form-input>
                        </b-col>
                    </b-row>
                     <b-row class="mt-2">
                        <b-col sm="2">
                        <label for="password">Choose your password:</label>
                        </b-col>                        
                        <b-col sm="4">
                          <b-form-input id="password" type="password" v-model="register.password" placeholder="Type your password here...."/>
                          <password-meter :password="register.password" @score="onScore" :title="passwordstrength"/>
                        </b-col>
                       <b-col sm="2">
                        <label for="retype">Retype your password:</label>
                        </b-col>                        
                        <b-col sm="4">
                        <b-form-input
                            id="retype"
                            type="password"
                            placeholder="Retype your password here...."
                            v-model="retypedpassword"
                        ></b-form-input>
                        </b-col>                        
                    </b-row>
                     <b-row class="mt-2">
                        <b-col sm="2">
                        <label for="email">Your email:</label>
                        </b-col>                        
                        <b-col sm="4">
                        <b-form-input
                            id="email"
                            type="email"
                            placeholder="Type your email address here...."
                            v-model="register.email"
                        ></b-form-input>
                        </b-col>
                        <b-col sm="2">
                        <label for="mobile">Your mobile number:</label>
                        </b-col>                        
                        <b-col sm="4">
                        <b-form-input
                            id="mobile"
                            placeholder="Type your mobile number here...."
                            v-model="register.mobile"
                        ></b-form-input>
                        </b-col>
                    </b-row>
                     <b-row class="mt-2">
                        <b-col sm="2">
                        <label for="address">Your address:</label>
                        </b-col>                        
                        <b-col sm="4">
                        <b-form-textarea
                            id="address"
                            placeholder="Type your address (street,zip,state,PO box) here...."
                            v-model="register.address"
                        ></b-form-textarea>
                        </b-col>
                        <b-col sm="2">
                        <label for="country">Your country:</label>
                        </b-col>                        
                        <b-col sm="2">
                          <vue-country-code
                            @onSelect="onSelectCountry" enabledCountryCode="true">
                          </vue-country-code>
                        </b-col>
                    </b-row>

                    <br/>                   
                    <b-row>
                      <b-col sm="2"></b-col>                        
                      <b-col sm="10">
                        <vue-hcaptcha v-bind:sitekey="sitekey" 
                            theme="light"
                            @verify="onCallback"
                            @expired="onExpire"
                            @error="onError" ></vue-hcaptcha>
                      </b-col>                        
                    </b-row>
                    <br/>                   
                    <b-button type="submit" variant="primary" :disabled="isDisabled">Submit</b-button>
                    &nbsp;<b-button type="reset" variant="danger">Reset</b-button>
                </b-form>
            </b-card-text>
        </b-card>
        <br/> 
    </b-container>
  </div>
</template>

<script>
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
  import passwordMeter from "vue-simple-password-meter";
  import VueCountryCode from "vue-country-code-select";
  export default {
    data() {
      return {
        passwordscore: null,
        passwordstrength: '',
        sitekey:this.$root.hcaptchaSitekey,
        hcaptchaVerified : !this.$root.hcaptchaEnabled,
        errors: [],
        retypedpassword:'',
        register: {
          name: '',
          login: '',
          password: '',
          email:'',
          mobile:'',
          address:'',
          country:''
        }
      }
    },
    methods: {
      //#region Country select event
      onSelectCountry({name, iso2, dialCode}) {
        this.register.country = iso2;
        console.log(name, iso2, dialCode)
      },
      //#endregion
      //#region Password meter.
      onScore({ score, strength }) {
        console.log(score); // from 0 to 4
        console.log(strength); // one of : 'risky', 'guessable', 'weak', 'safe' , 'secure' 
        this.passwordscore = score;
        this.passwordstrength = strength;
      },
      //#endregion
      //#region hcaptcha events
      onCallback(response) {
          this.hcaptchaVerified = true;
          console.log(`hCaptcha Callback Response: ${response}`);
        },
      onExpire() {
        this.hcaptchaVerified = false;
        console.log("hCaptcha Expired");
      },
      onError(err) {
        console.log(`hCaptcha Error: ${err}`);
      },
      //#endregion
      //#region regular events
      onSubmit(event) {
        event.preventDefault()
        this.validate();
        this.$restPost('/backoffice/customer/add',this.register, this.callbackOKSubmit,'Error sending the registration.');
      },
      callbackOKSubmit(){
        this.reset();
        this.$swal("Thank you for your registration!", "Check your mailbox for the confirmation.", "success");
      },
      onReset(event) {
        event.preventDefault();
        this.reset();
      },
      //#endregion
      //#region local methods 
      validate: function () {
        this.errors = [];
        if (this.register.name == '') this.errors.push('Name is required.');
        if (this.register.login == '') this.errors.push('Login is required.');
        if (this.register.password == '') this.errors.push('Password is required.');
        if (this.register.email == '') this.errors.push('Email is required.');
        if (this.errors.length > 0 ) return;
        if (this.register.name.length < 5 || this.register.name.length > 40 ) this.errors.push('Name length between 5 and 40.');
        if (this.register.login.length < 5 || this.register.login.length > 40 ) this.errors.push('Login length between 5 and 40.');
        if (this.register.login.length > 40 ) this.errors.push('Password length max 40.');
        if (this.passwordscore < 3 ) this.errors.push('Password to simple, strength '+this.passwordstrength+".");
        if (this.errors.length > 0 ) return;
        if (this.register.password != this.retypedpassword)  this.errors.push('Password and retyped password are not the same.');
        this.validateLogin();
      },
      validateLogin: function(){
        // https://www.regextester.com/95029
        let checkString = /[^A-Za-z0-9#$%@&]+/g;
        let found = this.register.login.match(checkString);
        if(!found || found.length == 0 ){ 
          return
        }
        else {
          this.errors.push('Login not valid, allowed characters a-z, A-Z, 0-9, #, $, @, &, %.');
        }
      },
      reset: function(){
        // Reset our form values
          this.register.name = '';
          this.register.login = '';
          this.register.password = '';
          this.register.email ='';
          this.register.mobile = '';
          this.register.address = '';
          this.register.country = '';
          this.retypedpassword = '';
      }
      //#endregion
     
    },
    components: {
      VueHcaptcha, passwordMeter , VueCountryCode
    },
    computed:{
      isDisabled(){
        return !this.hcaptchaVerified;
      }
    }
  }
</script>
<style scoped>
</style>