<template>
  <div class="Contact">
    <b-container>
        <br/> 
        <b-card>
            <b-card-header class="h5 mb-2 psm-header">Downloads</b-card-header>
            <b-card-text>
                <b-row class="mt-4">
                    <b-col sm="12">
                        All of the components, except the agent, can be installed by using one of the scripts below.
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col sm="4">
                    PSM-Docker.ps1, for Windows and Linux with Powershell installed.
                    </b-col>
                    <b-col sm="2">
                        <b-button variant="danger" @click="downloadScript('ps1')">Download</b-button> 
                    </b-col>
                </b-row>
                <b-row class="mt-4 mb-4">
                   <b-col sm="4">
                    PSM-Docker.sh, for Linux <b>without</b> Powershell installed<i>(under development).</i>
                    </b-col>
                    <b-col sm="2">
                        <b-button variant="danger" @click="downloadScript('sh')" disabled>Download</b-button> 
                    </b-col>
                </b-row>
                <b-card-header class="h6 mb-2 psm-header">Agent</b-card-header>               
                <b-row class="mt-4">
                   <b-col sm="12"> 
                    For the agent there are the next different flavours (RID's), broken down by processor architecture and operating system. 
                    For more information about which one to choose 
                    <b-link href="https://docs.microsoft.com/en-us/dotnet/core/rid-catalog" target='_blank'>.NET RID Catalog.</b-link>
                    <p>If you encounter problems you can request for a more specific flavour, for instance <i>ubuntu.18.04-x64</i>. 
                    You can also request for a macOS agent.</p>
                   </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col sm="4">
                    Windows, X86
                    </b-col>
                    <b-col sm="2">
                        <b-button variant="danger"><b-link :href='downloads.winx86' target='_blank' style='color:white'>Download</b-link></b-button> 
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col sm="4">
                    Windows, X64
                    </b-col>
                    <b-col sm="2">
                        <b-button variant="danger"><b-link :href='downloads.winx64' target='_blank' style='color:white'>Download</b-link></b-button> 
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col sm="4">
                    Linux, X64
                    </b-col>
                    <b-col sm="2">
                        <b-button variant="danger"><b-link :href='downloads.linuxx64' target='_blank' style='color:white'>Download</b-link></b-button> 
                    </b-col>
                </b-row>                
               <b-row class="mt-4">
                    <b-col sm="4">
                    Windows, arm
                    </b-col>
                    <b-col sm="2">
                        <b-button variant="danger"><b-link :href='downloads.winarm' target='_blank' style='color:white'>Download</b-link></b-button> 
                    </b-col>
                </b-row>
               <b-row class="mt-4">
                    <b-col sm="4">
                    Windows, arm64
                    </b-col>
                    <b-col sm="2">
                        <b-button variant="danger"><b-link :href='downloads.winarm64' target='_blank' style='color:white'>Download</b-link></b-button> 
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col sm="4">
                    Linux, arm
                    </b-col>
                    <b-col sm="2">
                        <b-button variant="danger"><b-link :href='downloads.linuxarm' target='_blank' style='color:white'>Download</b-link></b-button> 
                    </b-col>
                </b-row>                
                <b-row class="mt-4">
                    <b-col sm="4">
                    Linux, arm64
                    </b-col>
                    <b-col sm="2">
                        <b-button variant="danger"><b-link :href='downloads.linuxarm64' target='_blank' style='color:white'>Download</b-link></b-button> 
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col sm="4">
                    Linux, musl arm64
                    </b-col>
                    <b-col sm="2">
                        <b-button variant="danger"><b-link :href='downloads.linuxmuslarm64' target='_blank' style='color:white'>Download</b-link></b-button> 
                    </b-col>
                </b-row>
              </b-card-text>
        </b-card>
        <br/> 
    </b-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        id:0,
        downloadUrl:'',
        installScriptPowershell :'',
        installScriptShell : 'Under development',
        downloads: {
          winx86 : '',
          winx64 : '',
          linuxarm : '',
          linuxarm64 : '',
          linuxmuslarm64 : '',
          linuxx64 : '',
          winarm : '',
          winarm64 : ''
        }
      }
    },
    created(){
        this.installScriptPowershell = this.$store.state.installScripts.powershell;
        this.downloadUrl = this.$store.state.downloadUrl;
        this.downloads.winx86 = this.downloadUrl + '/PSM.Agent.win-x86.zip';
        this.downloads.winx64 = this.downloadUrl + '/PSM.Agent.win-x64.zip';
        this.downloads.linuxarm = this.downloadUrl + '/PSM.Agent.linux-arm.zip';
        this.downloads.linuxarm64 = this.downloadUrl + '/PSM.Agent.linux-arm64.zip';
        this.downloads.linuxmuslarm64 = this.downloadUrl + '/PSM.Agent.linux-musl-arm64.zip';
        this.downloads.linuxx64 = this.downloadUrl + '/PSM.Agent.linux-x64.zip';
        this.downloads.winarm = this.downloadUrl + '/PSM.Agent.win-arm.zip';
        this.downloads.winarm64 = this.downloadUrl + '/PSM.Agent.win-arm64.zip';
},
    methods: {
        downloadScript(extension) {
 
        // Create an invisible A element
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);

        // Set the HREF to a Blob representation of the data to be downloaded
        var type = 'text/plain';
        var content = this.installScriptPowershell;
        var scriptName = 'PSM-Docker.ps1';
        if (extension === 'sh'){
            content = this.installScriptShell;
            scriptName = 'PSM-Docker.sh';
        }
        a.href = window.URL.createObjectURL(
            new Blob([content], { type })
        );

        // Use download attribute to set set desired file name
        a.setAttribute("download", scriptName);

        // Trigger the download by simulating click
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
        },
        downloadAgent(id) {
            this.id = id;
            this.$restPost('/backoffice/getfile', {params : {id : this.id}}, this.callbackOKDownLoadAgent, 'Error getting the agent.' );
        },
        callbackOKDownLoadAgent(response){
           let blob = new Blob([response.data]);
           let link = document.createElement('a');
           link.href = window.URL.createObjectURL(blob);
           link.download = this.id;
           link.click();
        }
    },
    components: {
    },
    computed:{
    }
  }
</script>
<style scoped>
</style>