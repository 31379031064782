<template>
  <div class="Profile">
    <br/>
    <b-container>
      <b-card>
        <b-card-header class="h6 mb-1 psm-header">Profile</b-card-header>
        <NewPassword/>
        <License/>
      </b-card>
    </b-container>
    <br/>
  </div>
</template>

<script>
  import NewPassword from './parts/NewPassword.vue';
  import License from './parts/License.vue';
  export default {
    data() {
      return {
      }
    },
    components: {
      NewPassword, License
    }
  }
</script>
<style scoped>
</style>