<template>
        <font-awesome-icon icon="times" style="color:red" @click="closeParentComponent" 
            class="psm-cursor-pointer" title="Close." />
</template>

<script>
export default {
     methods: {
       closeParentComponent(){
            this.$emit('close');
        }
    }
}
</script>