//#region Imports
//#region Basics
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
//#endregion
//#region Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
//#endregion
//#region Application
import App from './App.vue';
import Home from './components/Home.vue';
import Contact from './components/Contact.vue';
import Scripts from './components/Scripts.vue';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import Verify from './components/Verify.vue';
import Licensing from './components/Licensing.vue';
import Profile from './components/Profile.vue';
import RequestPassword from './components/RequestPassword.vue';
import NewScript from './components/parts/NewScript.vue';
import MyScripts from './components/MyScripts.vue';
import Docs from './components/Docs.vue';
import Downloads from './components/Downloads.vue';
import About from './components/About.vue';
import VersionCheck from './components/VersionCheck.vue';
//#endregion
//#region Utilities 
import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import Clipboard from 'v-clipboard';
import axios from 'axios';
//#endregion
//#region TypeAhead dropdown.
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
//#endregion
//#region EasyTable
import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library
//#endregion
//#region Font Awesome.
import { library } from '@fortawesome/fontawesome-svg-core'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faSitemap } from '@fortawesome/free-solid-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//#endregion
//#region PSM
import './assets/css/psm.css';
//#endregion
//#endregion

//#region Font Awesome, load icons.
library.add(faClipboard);
library.add(faFolderOpen);
library.add(faTimes);
library.add(faSitemap);
library.add(faSpinner);
//#endregion

//#region Initialisation
Vue.use(VueRouter);
Vue.use(VueSweetalert2);
Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Clipboard);
Vue.component('v-select', vSelect);
Vue.use(VueEasytable);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;
//#endregion

//#region Global methods.
Vue.prototype.$translateAndLogRestError = function (error) {
  console.log(error);
  return error.message;
}
Vue.prototype.$translateLicenseType = function (licenseType) {
  switch(licenseType){ 
    case 1:
      return "Free license";
    case 2:
      return "Standard license";
    case 3:
      return "Enterprise license";
    default:
      return "Temporary license";
  }   
}
Vue.prototype.$formatDate = function (dateTimeString){
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  var dateTime = new Date(dateTimeString);
  var result = dateTime.toLocaleDateString(options);
  return result;
}
Vue.prototype.$getGuid = function (){
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
Vue.prototype.$differenceInDays = function(date1, date2)
{
 const _MS_PER_DAY = 1000 * 60 * 60 * 24;
 // Discard the time and time-zone information.
 const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
 const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

 return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
//#region Predefined tags
Vue.prototype.$getTagsPredefined = function(){
    this.$restPost('/backoffice/script/getpredefinedtags',null, this.$setTagsPredefined,"Error getting the predefined tags.");
}
Vue.prototype.$setTagsPredefined = function(response){
  var tagsPredefined = { category : response.data.Data.Category, database : response.data.Data.Database
    , windows: response.data.Data.Windows, linux: response.data.Data.Linux
    , scriptlanguages: response.data.Data.ScriptLanguage };
  vm.$store.commit('setPredefinedTags', tagsPredefined);  
}
//#endregion
//#region Customtags
Vue.prototype.$getTagsCustom = function(){
  this.$restPost('/backoffice/script/getcustomtags',null, this.$setTagsCustom,'Error getting the custom tags.');
}
Vue.prototype.$setTagsCustom = function(response){
  var tagsCustom = response.data.Data;
  vm.$store.commit('setCustomTags', tagsCustom);
}
//#endregion
//#region Docs URL
Vue.prototype.$getDocsUrl = function(){
  var parm =  { Value : 'DOCS-URL' };
  this.$restPost('/backoffice/getsetting',parm, this.$setDocsUrl,'Error getting setting (DOCS-URL).');
}
Vue.prototype.$setDocsUrl = function(response){
  var docsUrl = response.data.Data.Value;
  vm.$store.commit('setDocsUrl', docsUrl);
}
//#endregion
//#region Download URL
Vue.prototype.$getDownloadUrl = function(){
  var parm =  { Value : 'DOWNLOAD-URL' };
  this.$restPost('/backoffice/getsetting',parm, this.$setDownloadUrl,'Error getting setting (DOWNLOAD-URL).');
}
Vue.prototype.$setDownloadUrl = function(response){
  var downloadUrl = response.data.Data.Value;
  vm.$store.commit('setDownloadUrl', downloadUrl);
}
//#endregion
//#region Website CLosed
Vue.prototype.$getWebsiteState = function(callback){
  var parm =  { Value : 'WEBSITE-CLOSED' };
  Vue.prototype.$callSetWebsiteState = callback;
  this.$restPost('/backoffice/getsetting',parm, this.$setWebsiteState,'Error getting setting (WEBSITE-CLOSED).');
}
Vue.prototype.$setWebsiteState = function(response){
  var isClosed = response.data.Data.Value;
  var websiteState = 'OPEN';  
  if (isClosed==='true'){
    websiteState = 'CLOSED';
  }
  vm.$store.commit('setWebsiteState', websiteState);
  // Set state om component.
  vm.$callSetWebsiteState(websiteState);
}
//#endregion
//#region Install script
Vue.prototype.$getInstallScripts = function(){
       // Get script data (text).
       var scriptName = 'PSM-docker.ps1';
       var parm =  { Value : scriptName };
       this.$restPost('/backoffice/getscript',parm, this.$setInstallScripts,'Error getting the script content.');
}
Vue.prototype.$setInstallScripts = function(response){
  var content = response.data.Data.Content;
  vm.$store.commit('setInstallScriptPowershell', content);
}
//#endregion
//#region REST funtions
Vue.prototype.$restPost = function(url, parm, callbackOK, errorMsg){
  var baseurl=process.env.VUE_APP_BACKEND_URL;
  // url should start with /
  var fullurl= baseurl + url;
  axios.post(fullurl, parm )
       .then((response) => { 
            console.log(response);
            if (response.data.Error){
              var msg = '';
              if (response.data.ApplicationError )
              {
                msg += response.data.ApplicationError+'<br/>';
              }
              if (response.data.Exception )
              {
                msg += response.data.Exception+'<br/>';
              }            
              this.$swal(errorMsg, msg, "error");
            }
            else{
              if (callbackOK){
                callbackOK(response);
              }
            }
      })
      .catch((error) => {
        console.log(error);
        var errormessage = this.$translateAndLogRestError(error);
        this.$swal(errorMsg, errormessage, "error");
      })     

}
//#endregion

//#endregion

//#region Store/state
const store = new Vuex.Store({
  state: {
    account : {
      id: null,
      name:'',
      login: '',
      password: '',
      email:'',
      token:''
    },
    license : {
      retrieved:false,
      hasLicense:false,
      content : '',
      licenseType : '',
      licenseTypeNr : 0,
      productKey : '',
      valid : false,
      validated : '',
      validatedDt : null,
      expires : '',
      expiresDt : null
    },
    tagsPredefined:{
      category:[],
      database:[],
      windows:[],
      linux:[],
      scriptlanguages:[]
    },
    tagsCustom : [],
    docsUrl : '',
    downloadURl:'',
    installScripts : {
      powershell : '',
      shell : ''
    },
    // UNKNOWN, CLOSED or OPEN.
    websiteState : 'UNKNOWN',
    websiteStateListeners : []
  },
  mutations: {
    setAccount (state, account) {
      state.account = account;
    },
    resetAccount(state){
      state.account.id = null;
      state.account.name='';
      state.account.login='';
      state.account.password='';
      state.account.email='';
      state.account.token='';
    },
    setLicense(state, license){
      state.license = license;
    },
    resetLicense(state){
      state.license.retrieved = false;
      state.license.hasLicense = false;
      state.license.content = '';
      state.license.licenseType = '';
      state.license.licenseTypeNr = 0;
      state.license.productKey = '';
      state.license.valid = false;
      state.license.validated = '';
      state.license.expires = '';
    },
    setPredefinedTags(state, tagsPredefined)
    {
      state.tagsPredefined = tagsPredefined;
    },
    // If the number of custom tags is becoming to big a different solution must be considered. A luxury problem.
    setCustomTags(state, tagsCustom)
    {
      state.tagsCustom = tagsCustom;
    },
    setDocsUrl(state, docsUrl){
      state.docsUrl = docsUrl;
    },
    setDownloadUrl(state, downloadUrl){
      state.downloadUrl = downloadUrl;
    },
    setInstallScriptPowershell(state, content){
      state.installScripts.powershell = content;
    },
    setWebsiteState(state, websiteState){
      state.websiteState = websiteState;
      for (var i = 0;i < state.websiteStateListeners.length;i++)
      {
        state.websiteStateListeners[i](websiteState);
      }
    },
    setWebsiteStateListener(state, listener){
      state.websiteStateListeners.push(listener);
    }
  }
})
//#endregion

//#region Routing
const routes = [
   {path: '/Home', component: Home}
  ,{path: '/Contact', component: Contact}
  ,{path: '/Scripts', component: Scripts}
  ,{path: '/Login', component: Login}
  ,{path: '/Register', component: Register}
  ,{path: '/Verify', component: Verify}
  ,{path: '/Licensing', component: Licensing}
  ,{path: '/Profile', component: Profile}
  ,{path: '/RequestPassword', component: RequestPassword}
  ,{path: '/NewScript', component: NewScript}
  ,{path: '/MyScripts', component: MyScripts}
  ,{path: '/Docs', component: Docs}
  ,{path: '/Downloads', component: Downloads}
  ,{path: '/About', component: About}
  ,{path: '/VersionCheck', component: VersionCheck}
]
const router = new VueRouter({ routes})
//Start route.
router.replace('/Home');
//#endregion

//#region Application
var vm = new Vue( 
  { router,
    render: h => h(App),
    data() {
      return {
        ip: 'unknown',
        hcaptchaSitekey:'',
        hcaptchaEnabled:true,
      }
    },
    created() {
      // Globals
      const url = process.env.VUE_APP_GET_IP_URL;
      if (url){
        axios.get(url).then(response => {
          this.ip = response.data.ip;
        })
      }
      this.hcaptchaSitekey = process.env.VUE_APP_HCAPTCHA_SITEKEY;
      if (process.env.VUE_APP_HCAPTCHA_ENABLED == 'false') this.hcaptchaEnabled = false;
      // Load static data.
      this.$getTagsPredefined();
      this.$getTagsCustom();
      this.$getDocsUrl();
      this.$getInstallScripts();
      this.$getDownloadUrl();
   },
  store
}).$mount('#app')
//#endregion
