import { render, staticRenderFns } from "./NewScript.vue?vue&type=template&id=bed0153c&scoped=true&"
import script from "./NewScript.vue?vue&type=script&lang=js&"
export * from "./NewScript.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bed0153c",
  null
  
)

export default component.exports