<template>
  <div class="Contact">
    <b-container>
        <br/> 
        <b-card>
            <b-card-header class="h5 mb-2 psm-header">About</b-card-header>
            <b-card-text>
               <b-card class="mb-1">    
                    <b-card-header class="h6 mb-1 psm-header">
                        <span @click="toggleVisibilityCardJBakker" class="psm-cursor-pointer">
                        <b-icon-caret-down-fill v-if="!elements.cardJBakkerVisible" class="h6 mb-1" variant="light"/>
                        <b-icon-caret-up-fill   v-if="elements.cardJBakkerVisible" class="h6 mb-1" variant="light"/>
                        </span>
                        &nbsp;Johan Bakker
                    </b-card-header>
                    <b-card-text v-if="elements.cardJBakkerVisible">
                        <b-row class="mt-1">
                            <b-col sm="1">
                                <p><img src="../assets/img/jbakker.jpg" width="80" title="Johan Bakker" alt="Johan Bakker"></p>
                            </b-col>
                            <b-col sm="6" class="mt-2">
                                <p>Founder of <a href="https://www.pragmatic.nl" target="_blank">Pragmatic</a>, a global company ( 🤥, one employee ).
                                Specialized in .NET design and development, SQL Server, Sybase. Now also React JS and Vue JS.
                                Creator of the PSM software, conceived out of necessity.</p>
                            </b-col>
                        </b-row>
                    </b-card-text>
               </b-card>
               <b-card class="mb-1">    
                    <b-card-header class="h6 mb-1 psm-header">
                        <span @click="toggleVisibilityCardOther" class="psm-cursor-pointer">
                        <b-icon-caret-down-fill v-if="!elements.cardOtherVisible" class="h6 mb-1" variant="light"/>
                        <b-icon-caret-up-fill   v-if="elements.cardOtherVisible" class="h6 mb-1" variant="light"/>
                        </span>
                        &nbsp;Other contributors / Sponsored by / .......
                    </b-card-header>
                    <b-card-text v-if="elements.cardOtherVisible">
                        <b-row class="mt-1">
                            <b-col sm="6" class="mt-2">
                                <p> ??????? </p>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
             </b-card-text>
        </b-card>
        <br/> 
    </b-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        elements:{
            cardJBakkerVisible: true,
            cardOtherVisible: false,
        },
      }
    },
    methods: {
      toggleVisibilityCardJBakker(){
        this.elements.cardJBakkerVisible = !this.elements.cardJBakkerVisible;
      },         
      toggleVisibilityCardOther(){
        this.elements.cardOtherVisible = !this.elements.cardOtherVisible;
      },
    },
    components: {
    },
    computed:{
      contentUrl: function(){
        return this.docsUrl+':content';
      },
      introductionUrl: function(){
        return this.docsUrl+':introduction';
      },
    }
  }
</script>
<style scoped>
</style>