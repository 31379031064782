<template>
  <div class="Scripts">
    <b-container>
      <b-card class="mt-1 mb-1">
        <b-card-header class="h6 mb-1 psm-header">Scripts
          <p class="float-sm-right psm-cursor-pointer"><Info infoKey='infoSearch' infoTitle='How to find scripts.'/></p>
        </b-card-header>
        <div class="mt-2 mb-2">Use the form below to search for scripts that meet your requirements.</div>
        <b-card class="mb-1">    
            <b-card-header class="h6 mb-1 psm-header">
                <span @click="toggleVisibilityCardSearch" class="psm-cursor-pointer">
                  <b-icon-caret-down-fill v-if="!elements.cardSearchVisible" class="h6 mb-1" variant="light"/>
                  <b-icon-caret-up-fill   v-if="elements.cardSearchVisible" class="h6 mb-1" variant="light"/>
                </span>
                &nbsp;Search
            </b-card-header>
            <b-card-text v-if="elements.cardSearchVisible" >
              <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul>
                    <b-alert variant="danger" show>
                      <li v-for="error in errors" v-bind:key="error">
                        {{ error }}
                      </li>
                    </b-alert>
                  </ul>
              </p>
              <PredefinedTags ref="predefinedTags" :readonly="false" place-holder= 'Add or remove predefined tags...'/>
              <hr class="mt-2 mb-2" />
              <CustomTags ref="customTags"/>
              <hr class="mt-2 mb-2" />
              <b-row class="mt-1">
                <b-col sm="6">
                  <b-button class="mr-1" type="button" variant="primary" v-on:click="search(true)">Search</b-button>
                  <b-button class="mr-1" type="button" variant="danger" v-on:click="reset">Reset</b-button>
                </b-col>
                <b-col sm="6">
                  <div class='float-right'>
                  <b-form-checkbox size="sm" id="checkbox-add-result" v-model="addResult" name="checkbox-add-result" title="Add result to existing result.">
                    Add to existing result
                  </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
        </b-card>
        <b-card class="mb-1">    
            <b-card-header class="h6 mb-1 psm-header">
              <b-row>
                <b-col sm="3">
                  <span @click="toggleVisibilityCardResult" class="psm-cursor-pointer">
                    <b-icon-caret-down-fill v-if="!elements.cardResultVisible" class="h6 mb-1" variant="light"/>
                    <b-icon-caret-up-fill   v-if="elements.cardResultVisible" class="h6 mb-1" variant="light"/>
                  </span>
                  &nbsp;Result ( {{ result.tableData.length }} scripts)
                </b-col>
                <b-col sm="2"></b-col>
                <b-col sm="2">
                  <div class="float-sm-right">
                    <b-dropdown id="dropdown-form" text="Filter" ref="dropdownFilter" size="sm">
                      <b-dropdown-form>
                        <b-form-input size="sm" style='width:150px' v-model="result.filterValue" placeholder="Enter filter value..." @submit.stop.prevent></b-form-input>
                        <b-button size="sm" class='mt-2' type="button" v-on:click="applyFilter" title='Apply the filter.'>Apply</b-button>
                        <b-button size="sm" class='float-sm-right ml-2 mt-2' variant="danger" type="button" v-on:click="resetFilter" title='Reset the filter.'>Reset</b-button>
                      </b-dropdown-form>
                    </b-dropdown>
                  </div>
                </b-col>
                <b-col sm="5">
                  <p class="float-sm-right psm-cursor-pointer" v-if="(nrOfScripts % 100 === 0 && nrOfScripts !== 0)"
                    @click="search(false)">More scripts....
                  </p>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-text v-if="elements.cardResultVisible" >
              <b-row>
                <b-col sm="12">
                  <ve-table :columns="result.columns" :table-data="result.tableData" :sort-option="sortOption"
                    :cell-style-option="cellStyleOption" :event-custom-option="result.selectScriptEventOption"
                    :expand-option="result.expandRow" row-key-field-name="id"
                  ></ve-table> 
                </b-col>
              </b-row>
            </b-card-text>
        </b-card>
        <Script ref='scriptDetails'/>
      </b-card>
    </b-container>
  </div>
</template>

<script>
  import PredefinedTags from './parts/PredefinedTags.vue';
  import CustomTags from './parts/CustomTags.vue';
  import Info from './parts/Info.vue';
  import CopyClipboard from './parts/CopyClipboard.vue';
  import Script from './Script.vue';

  export default {
    data() {
      return {
        initial: true,
        nrOfScripts: 0,
        lastId: 0,
        addResult: false,
        errors: [],
        elements:{
           cardSearchVisible: true,
           cardResultVisible: false
        },
        searchTags:{
            TagsPredefined:[]
        },
        sortOption: {
          sortChange: (params) => {
            this.sortChange(params);
          },
        },
        cellStyleOption: {
          // eslint-disable-next-line no-unused-vars
          bodyCellClass: ({ row, column, rowIndex }) => {
            if (column.field !== "predefinedtags" && column.field !== "customtags") {
              return "table-body-cell-class1";
            }
          },
        },         
        result:{
          filterValue: '',
          selectScriptEventOption: {
            // eslint-disable-next-line no-unused-vars            
            bodyCellEvents: ({ row,column,rowIndx }) => {
              return {
              // eslint-disable-next-line no-unused-vars            
                click: (event) => {
                  if (column.field === 'detail')
                  {
                    this.elements.cardSearchVisible = false;
                    this.elements.cardResultVisible = false;
                    this.$refs.scriptDetails.setReadOnly(true);
                    this.$refs.scriptDetails.loadScriptDetails(row['key']);
                  }
                },
               };
            },
          },
          expandRow:{
            // eslint-disable-next-line no-unused-vars            
            render: ({ row, column, rowIndex }, h) => {
              return (
                <p class='psm-font-small'>
                  {row.description}
                </p>
              );            
            } 
          },   
          columns: [
            { field: "", key: "a0", type: "expand", title: "", width: 50, align: "center", },            
            { field: "", key: "a1", title: "", width: 10, align: "center",
              // eslint-disable-next-line no-unused-vars            
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                var val = row['key'];
                return <CopyClipboard valueToCopy={val} title="Copy script key to clipboard."/>;
              },
            },
            { field: "detail", key: "a2", title: "Details", width: 10, align: "center",
              // eslint-disable-next-line no-unused-vars            
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return <font-awesome-icon icon="folder-open" class="psm-folder-open-icon" title="Open script details." />
              },
            },
            { field: "key", key: "b", title: "Key", align: "left", width: "15%",
              // eslint-disable-next-line no-unused-vars
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                var key = row[column.field];
                var shortkey = key.substring(0,5)+'...'+key.substring(key.length-5);
                return (
                  <div title={key}>{shortkey}</div>
                  );
              }
            },
            { field: "name", key: "c", title: "Name", align: "left", sortBy: "", width: "35%"  },
            { field: "predefinedtags", key: "d", title: "Predefined Tags", align: "left" , width: "20%", 
              // eslint-disable-next-line no-unused-vars
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                var tags = row[column.field].substring(1).replace(/;/g,'; ').trim();
                return (
                  <div class="psm-tag-column">{tags}</div>
                  );
              }
            },
            { field: "customtags", key: "e", title: "Custom Tags", align: "left", width: "20%",
              // eslint-disable-next-line no-unused-vars
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                var tags = row[column.field].substring(1).replace(/;/g,'; ').trim();
                return (
                  <div class="psm-tag-column">{tags}</div>
                  );
              }
            },
            { field: "dated", key: "f", title: "Dated", align:"left", sortBy: "", width: "10%",
              // eslint-disable-next-line no-unused-vars
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                var dt = this.formatDate(row[column.field]);
                return ( <div>{dt}</div> );
              }
            },
          ],
          tableData:[],
          tableDataInitial:[]
        }         
      }
    },
    methods: {
      //#region Collapse
      toggleVisibilityCardSearch(){
        this.elements.cardSearchVisible = !this.elements.cardSearchVisible;
      }, 
      toggleVisibilityCardResult(){
        this.elements.cardResultVisible = !this.elements.cardResultVisible;
      },
      applyFilter(){
        if ( this.result.tableData === []) this.result.tableData = this.result.tableDataInitial;
        this.result.tableData = this.result.tableData.filter(row =>{return (row.key.includes(this.result.filterValue) || row.name.includes(this.result.filterValue)||
                                                                  row.predefinedtags.includes(this.result.filterValue) || row.customtags.includes(this.result.filterValue)||
                                                                  row.dated.includes(this.result.filterValue))});
        this.$refs.dropdownFilter.hide(true);
      }, 
      resetFilter(){
        this.result.tableData = this.result.tableDataInitial;
        this.result.filterValue = '';
        this.$refs.dropdownFilter.hide(true);
      }, 
      //#endregion
      //#region Search
      search:function(initial){
        this.initial= initial;
        if (this.initial){
          this.nrOfScripts =0;
          this.lastId = 0;
        }
        var parm =  { PredefinedTags : this.$refs.predefinedTags.$data.script.TagsPredefined, 
                      CustomTags : this.$refs.customTags.$data.result.TagsCustom,
                      LastId : this.lastId};        
        this.$restPost('/backoffice/script/search', parm, this.callbackSearchOK, 'Error searching scripts.', null, null)
      },
      callbackSearchOK:function(response){
        if (this.initial && !this.addResult)
          this.result.tableData = response.data.Data;
        else{
          this.result.tableData = this.result.tableData.concat(response.data.Data);
        }
        // Keep initial data for filter backup.
        this.result.tableDataInitial = this.result.tableData;
        this.nrOfScripts = this.result.tableData.length;
        if (this.nrOfScripts > 0){
          this.lastId = this.result.tableData[this.nrOfScripts - 1].id;
        }
        this.elements.cardResultVisible = true;
      },
      reset(){
        this.$refs.predefinedTags.$data.script.TagsPredefined = [];
        this.$refs.customTags.$data.result.TagsCustom = [];
        this.result.tableData = [];
        this.nrOfScripts = 0;
        this.lastId = 0;
      },
      //#endregion
      //#region Sort
     sortChange(params){
        this.result.tableData.sort((a, b) => {
          if (params.name) {
            if (params.name === "asc") {
              if (a.name > b.name ) return -1;
              if (a.name < b.name ) return 1;
              return 0;
            }
            if (params.name === "desc") {
              if (a.name > b.name ) return 1;
              if (a.name < b.name ) return -1;
              return 0;
            }
          }
          if (params.dated) {
            if (params.dated === "asc") {
              if (a.dated > b.dated ) return -1;
              if (a.dated < b.dated ) return 1;
              return 0;
            }
            if (params.dated === "desc") {
              if (a.dated > b.dated ) return 1;
              if (a.dated < b.dated ) return -1;
              return 0;
            }
          }
        })
      },
      //#endregion
      //#region Format
      formatDate(date){
        return ( date.substring(0,4)+"-"+date.substring(5,7)+"-"+date.substring(8,10)+" "+date.substring(11,13)+":"+date.substring(14,16) );
      }     
      //#endregion
 
    },
    components: {
      PredefinedTags, CustomTags, Info, Script
    }
  }
</script>
<style>
  .table-body-cell-class1 {
   font-size:0.9em;
   padding:0 10px;
  }
</style>