<template>
  <div class="NewPassword">
    <b-container>
        <br/> 
        <b-card>
            <b-card-header class="h6 mb-1 psm-header">
                <span @click="toggleVisibilityCard" class="psm-cursor-pointer">
                  <b-icon-caret-down-fill v-if="!elements.cardVisible" class="h6 mb-1" variant="light"/>
                  <b-icon-caret-up-fill   v-if="elements.cardVisible" class="h6 mb-1" variant="light"/>
                </span>
                &nbsp;New password
            </b-card-header>
            <b-card-text v-if="elements.cardVisible" >
              <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul>
                    <b-alert variant="danger" show>
                      <li v-for="error in errors" v-bind:key="error">
                        {{ error }}
                      </li>
                    </b-alert>
                  </ul>
              </p>
                <b-form @submit="onSubmit">
                  <b-row class="mt-2">
                    <b-col sm="2">
                      <label for="password">Choose your password:</label>
                    </b-col>                        
                    <b-col sm="4">
                      <b-form-input id="password" type="password" v-model="newpassword.password" placeholder="Type your password here...."/>
                        <password-meter :password="newpassword.password" @score="onScore" :title="newpassword.passwordstrength"/>
                    </b-col>
                    <b-col sm="2">
                        <label for="retype">Retype your password:</label>
                        </b-col>                        
                        <b-col sm="4">
                        <b-form-input
                            id="retype"
                            type="password"
                            placeholder="Retype your password here...."
                            v-model="newpassword.retypedpassword"
                        ></b-form-input>
                    </b-col>                        
                  </b-row>  
                  <b-button type="submit" variant="primary" :disabled="!isPasswordChanged">Submit</b-button>
                </b-form>
            </b-card-text>
        </b-card>
    </b-container>
  </div>
</template>

<script>
  import passwordMeter from 'vue-simple-password-meter';
  export default {
    data() {
      return {
         errors: [],
         elements:{
           cardVisible: false
         },
         newpassword:{
            password:'',
            retypedpassword:'',
            passwordstrength:'',
            passwordscore:null
         }
          
      }
    },
    methods: {
      //#region Password.
      onScore({ score, strength }) {
        console.log(score); // from 0 to 4
        console.log(strength); // one of : 'risky', 'guessable', 'weak', 'safe' , 'secure' 
        this.newpassword.passwordscore = score;
        this.newpassword.passwordstrength = strength;
      },
      onSubmit(event) {
        event.preventDefault()
        this.validatePassword();
        if (this.errors.length == 0){
          var parm =  { customerId : this.$store.state.account.id, token : this.$store.state.account.token, newPassword : this.newpassword.password};
          this.$restPost('/backoffice/customer/newpassword', parm, this.callbackOKSubmit, 'Error sending the new password.' );
        }
      },
      callbackOKSubmit(){
        this.$swal("New password", "Your password change was succesfull.", "success");
      },
      toggleVisibilityCard(){
        this.elements.cardVisible = !this.elements.cardVisible;
      },
      //#endregion 
      //#region local methods 
      validatePassword: function () {
        this.errors = [];
        if (this.newpassword.password == '') this.errors.push('Password is required.');
        if (this.newpassword.retypedpassword == '') this.errors.push('Retyped Password is required.');
        if (this.errors.length > 0 ) return;
        if (this.newpassword.passwordscore < 3 ) this.errors.push('Password to simple, strength '+this.newpassword.passwordstrength+".");
        if (this.newpassword.password != this.newpassword.retypedpassword)  this.errors.push('Password and retyped password are not the same.');
        if (this.errors.length > 0 ) return;
      }
    },
    components: {
      passwordMeter
    },
    computed:{
      isPasswordChanged(){
        return (this.newpassword.password != '' && this.$store.state.account.password != this.newpassword.password)
      }
 
    }
  }
</script>
<style scoped>
</style>