<template>
  <div class="Contact">
    <b-container>
        <br/> 
        <b-card>
            <b-card-header class="h5 mb-2 psm-header">Verify Registration</b-card-header>
            <b-card-text>
              <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul>
                    <b-alert variant="danger" show>
                      <li v-for="error in errors" v-bind:key="error">
                        {{ error }}
                      </li>
                    </b-alert>
                  </ul>
              </p>
                To verify your registration fill in your email address and the key from the registration mail.
                <b-form @submit="onSubmit" @reset="onReset">
                     <b-row class="mt-2">
                        <b-col sm="2">
                        <label for="email">Your email:</label>
                        </b-col>                        
                        <b-col sm="10">
                        <b-form-input
                            id="email"
                            type="email"
                            placeholder="Type your email address here...."
                            v-model="register.email"
                        ></b-form-input>
                        </b-col>
                    </b-row>
                     <b-row class="mt-2">
                        <b-col sm="2">
                        <label for="key">Your key:</label>
                        </b-col>                        
                        <b-col sm="10">
                        <b-form-textarea
                            id="key"
                            type="textarea"
                            rows="2"
                            placeholder="Type or paste the key that you received by mail here...."
                            v-model="register.key"
                        ></b-form-textarea>
                        </b-col>
                    </b-row>
                   <br/>                   
                    <b-row>
                      <b-col sm="2"></b-col>                        
                      <b-col sm="10">
                        <vue-hcaptcha v-bind:sitekey="sitekey" 
                            theme="light"
                            @verify="onCallback"
                            @expired="onExpire"
                            @error="onError" ></vue-hcaptcha>
                      </b-col>                        
                    </b-row>
                    <br/>                   
                    <b-button type="submit" variant="primary" :disabled="isDisabled">Submit</b-button>
                    &nbsp;<b-button type="reset" variant="danger">Reset</b-button>
                </b-form>
            </b-card-text>
        </b-card>
        <br/> 
    </b-container>
  </div>
</template>

<script>
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
  export default {
    data() {
      return {
        sitekey:this.$root.hcaptchaSitekey,
        hcaptchaVerified : !this.$root.hcaptchaEnabled,
        errors: [],
        register: {
          email:'',
          key:''
         }
      }
    },
    methods: {
     //#region hcaptcha events
      onCallback(response) {
          this.hcaptchaVerified = true;
          console.log(`hCaptcha Callback Response: ${response}`);
        },
      onExpire() {
        this.hcaptchaVerified = false;
        console.log("hCaptcha Expired");
      },
      onError(err) {
        console.log(`hCaptcha Error: ${err}`);
      },
      //#endregion
      //#region regular events
      onSubmit(event) {
        event.preventDefault()
        this.validate();
        this.$restPost('/backoffice/customer/verifymanual', this.register, this.callbackOKSubmit, 'Error verifying the registration.' );
      },
      callbackOKSubmit(){
        this.$swal("Verification successfull!", "You can now login.", "success");
        this.reset();
      },
      onReset(event) {
        event.preventDefault();
        this.reset();
      },
      //#endregion
      //#region local methods 
      validate: function () {
        this.errors = [];
        if (this.register.email == '') this.errors.push('Email is required.');
        if (this.register.key == '') this.errors.push('Key is required.');
        if (this.errors.length > 0 ) return;
      },
      reset: function(){
        // Reset our form values
          this.register.email ='';
          this.register.key = '';
      }
      //#endregion
     
    },
    components: {
      VueHcaptcha
    },
    computed:{
      isDisabled(){
        return !this.hcaptchaVerified;
      }
    }
  }
</script>
<style scoped>
</style>