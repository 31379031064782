<template>
  <div class="Licensing">
    <br/>
    <b-container>
      <b-card>
        <b-card-header class="h5 mb-1 psm-header">Licensing</b-card-header>
            <b-row>
                <b-col sm="4"></b-col>
                <b-col sm="2"><b>Free</b></b-col>
                <b-col sm="2"><b>Standard</b></b-col>
                <b-col sm="2"><b>Enterprise</b></b-col>
            </b-row>
            <b-row>
                <b-col sm="4">Number of installations*</b-col>
                <b-col sm="2">One</b-col>
                <b-col sm="2">One</b-col>
                <b-col sm="2">Unlimited</b-col>
            </b-row>
            <b-row>
                <b-col sm="4">Server objects per installation</b-col>
                <b-col sm="2">10**</b-col>
                <b-col sm="2">Unlimited</b-col>
                <b-col sm="2">Unlimited</b-col>
            </b-row>
            <b-row>
                <b-col sm="4">Notifications</b-col>
                <b-col sm="6"></b-col>
            </b-row>
            <b-row>
                <b-col sm="4">- Email</b-col>
                <b-col sm="2" class="psm-ok-color h5"><b-icon icon="check-square"></b-icon></b-col>
                <b-col sm="2" class="psm-ok-color h5"><b-icon icon="check-square"></b-icon></b-col>
                <b-col sm="2" class="psm-ok-color h5"><b-icon icon="check-square"></b-icon></b-col>
            </b-row>
            <b-row>
                <b-col sm="4">- SMS</b-col>
                <b-col sm="2" class="psm-ok-color h5"><b-icon icon="check-square"></b-icon></b-col>
                <b-col sm="2" class="psm-ok-color h5"><b-icon icon="check-square"></b-icon></b-col>
                <b-col sm="2" class="psm-ok-color h5"><b-icon icon="check-square"></b-icon></b-col>
            </b-row>
            <b-row>
                <b-col sm="4">- Telegram</b-col>
                <b-col sm="2" class="psm-ok-color h5"><b-icon icon="check-square"></b-icon></b-col>
                <b-col sm="2" class="psm-ok-color h5"><b-icon icon="check-square"></b-icon></b-col>
                <b-col sm="2" class="psm-ok-color h5"><b-icon icon="check-square"></b-icon></b-col>
            </b-row>
             <b-row>
                <b-col sm="4">Supported database management systems***</b-col>
                <b-col sm="2">SQLite</b-col>
                <b-col sm="2">One</b-col>
                <b-col sm="2">Multiple</b-col>
            </b-row>
            <b-row>
                <b-col sm="4">Support tickets per year</b-col>
                <b-col sm="2">--</b-col>
                <b-col sm="2">10</b-col>
                <b-col sm="2">Fair use</b-col>
            </b-row>
            <b-row>
                <b-col sm="4"><b>Pricing per year</b></b-col>
                <b-col sm="2"><b>--</b></b-col>
                <b-col sm="2"><b>$ 95</b></b-col>
                <b-col sm="2"><b>$ 495</b></b-col>
            </b-row>
        </b-card>
        <b-card>
            <b-card-header class="h6 mb-1 psm-header">* What is one installation ?</b-card-header>
            One installation is a set of collaborating PSM components using one repository (database). 
            For instance multiple Docker containers with PSM components working together using one repository are considered one installation.
        </b-card>
        <b-card>
            <b-card-header class="h6 mb-1 psm-header">** Earning server objects.</b-card-header>
            You can earn extra server objects by uploading scripts for public use. Your account profile will show the number of earned server objects.
            The number of earned server objects are included in your license. This implies that you will have to load the changed license if you want to benefit.
            The number of unique usages by others will determine the number of earned server objects. 😉 
        </b-card>
       <b-card>
            <b-card-header class="h6 mb-1 psm-header">*** Databases.</b-card-header>
            With the free license you are bound to the SQLite database that comes with the software. This is the default option. 
            When purchasing the standard license you are entitled to request the scripting for one other DBMS. 
            Finally with the Enterprise license you can request for any or all of the DBMS scripts.
            <i>At the moment this feature is under development and not yet available.</i>
        </b-card>

    </b-container>
    <br/>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    components: {
    }
  }
</script>
<style scoped>
</style>