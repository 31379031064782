<template>
    <b-icon-info-circle-fill title='How does it work to find scripts.' v-on:click='showInfo'/>
</template>

<script>
  export default {
    data() {
      return {

      }   
    },
    methods: {
     showInfo(){
        var parm =  { InfoKey : this.infoKey};
        this.$restPost('/backoffice/getinfotext', parm, this.callbackOKShowInfo, 'Error getting info text.');
      },
      callbackOKShowInfo(response){
        var text = response.data;
         var title = '<b>'+this.infoTitle+'</b>';
         this.$swal.fire({
             title: title,
             type: 'info',
             customClass: 'psm-info-text',
             html: text
         });
      }
    },
    components: {
    },
    props:['infoKey', 'infoTitle']
  }
</script>
<style>
</style>