<!-- https://www.digitalocean.com/community/tutorials/vuejs-file-reader-component -->
<template>
  <div class="TextReader">
    <b-form-file id="script-file" @change="loadTextFromFile" 
      placeholder="Select or drop your file here..." title="Select a file with the Browse button or drag and drop a file here..."></b-form-file>
  </div>
</template>

<script>
export default {
  methods: {
    loadTextFromFile(ev) {
      // Bug : drag and drop uses ev.dataTransfer.files[0] instead of ev.target.files[0];
      let file=null;
      if (ev.target.files && ev.target.files.length > 0)
        file = ev.target.files[0];
      else if ( ev.dataTransfer.files && ev.dataTransfer.files.length)
        file = ev.dataTransfer.files[0];
      else
        this.$swal("File selection", "No file detected!", "error");

      const reader = new FileReader();

      reader.onload = e => this.$emit("load", e.target.result);
      reader.readAsText(file);
    }
  }
};
</script>