<template>
  <div class="Contact">
    <b-container>
        <br/> 
        <b-card>
            <b-card-header class="h5 mb-2 psm-header">Request a new password</b-card-header>
            <b-card-text>
              <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul>
                    <b-alert variant="danger" show>
                      <li v-for="error in errors" v-bind:key="error">
                        {{ error }}
                      </li>
                    </b-alert>
                  </ul>
              </p>
                Fill in your login or email address below and press Submit. If you are known in our administration you will receive an email with a new password.
                We recommend to change this password as soon as possible.
                <b-form @submit="onSubmit">
                    <b-row class="mt-2">
                        <b-col sm="2">
                        <label for="name">Your login or email address:</label>
                        </b-col>                        
                        <b-col sm="4">
                        <b-form-input
                            id="name"
                            placeholder="Type your login or email address here...."
                            v-model="passwordrequest.customerId"
                        ></b-form-input>
                        </b-col>
                     </b-row>
                    <br/>                   
                    <b-button type="submit" variant="primary">Submit</b-button>
                </b-form>
            </b-card-text>
        </b-card>
        <br/> 
    </b-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        errors: [],
        passwordrequest: {
          customerId: ''
        }
      }
    },
    methods: {
      //#region regular events
      onSubmit(event) {
        event.preventDefault()
        this.validate();
        if (this.errors.length == 0){
          this.$restPost('/backoffice/customer/forgotpassword', this.passwordrequest, this.callbackOKSubmit, 'Error requesting a new password."');
        }
      },
      callbackOKSubmit(){
        this.$swal("Thank you for requesting a new password!", "Check your mailbox.", "success");
        this.errors = [];
        this.$router.push("/Login"); 
      },
      //#endregion
      //#region local methods 
      validate: function () {
        this.errors = [];
        if (this.passwordrequest.customerId == '') this.errors.push('Field is required.');
        if (this.errors.length > 0 ) return;
      }
      //#endregion
    },
    components: {
    },
    computed:{
      }
}
</script>
<style scoped>
</style>