<template>
    <div class="PredefinedTags">
        <b-row class="mt-2">
          <b-col sm="12">
              <b-form-tags
                 input-id="tags-predefined"
                 v-model="script.TagsPredefined"
                 tag-variant="primary"
                 tag-pills
                 :limit="limit" 
                 size="sm"
                 separator=" "
                 :placeholder="placeHolder"
                 disabled="true"
               ></b-form-tags>
          </b-col>
        </b-row>
        <b-row class="mt-1" v-if="!readonly">
             <b-col sm="6">
                <b-dropdown size="sm" id="dropdown-buttons" text="Category" class="mr-1">
                    <b-dropdown-item-button
                    v-for="value in this.tagsPredefined.category" :key="value"
                        @click="addTag(value)">{{value}}</b-dropdown-item-button>
                </b-dropdown>
                <b-dropdown size="sm" id="dropdown-buttons" text="Script Language" class="mr-1">
                    <b-dropdown-item-button
                    v-for="value in this.tagsPredefined.scriptlanguages" :key="value"
                        @click="addTag(value)">{{value}}</b-dropdown-item-button>
                </b-dropdown>
                <b-dropdown size="sm" id="dropdown-buttons" text="Linux" class="mr-1">
                    <b-dropdown-item-button
                    v-for="value in this.tagsPredefined.linux" :key="value"
                        @click="addTag(value)">{{value}}</b-dropdown-item-button>
                </b-dropdown>
                <b-dropdown size="sm" id="dropdown-buttons" text="Windows" class="mr-1">
                    <b-dropdown-item-button
                    v-for="value in this.tagsPredefined.windows" :key="value"
                        @click="addTag(value)">{{value}}</b-dropdown-item-button>
                </b-dropdown>
                <b-dropdown size="sm" id="dropdown-buttons" text="Database" class="mr-1">
                    <b-dropdown-item-button
                    v-for="value in this.tagsPredefined.database" :key="value"
                        @click="addTag(value)">{{value}}</b-dropdown-item-button>
                </b-dropdown>
            </b-col>
            <b-col sm="6" size="sm" v-if="!readonly">
                <div  class='float-right'>
                    <b-button size="sm" class='ml-1' type="button" v-on:click="removeLastTag" title='Remove last predefined tag.'>Remove last</b-button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
   data() {
      return {
        script : {
            TagsPredefined:[],
        },          
        tagsPredefined:{
            category:[],
            database:[],
            windows:[],
            linux:[],
            scriptlanguages:[]
        }
       }
    },
    created(){
        this.tagsPredefined = this.$store.state.tagsPredefined;
    }, 
    props: {
        readonly : { type : Boolean, default : false},
        limit : { type: Number, default : 10},
        placeHolder : { type : String, default : 'Predefined tags....' }
    } ,  
    methods: {
      addTag(tag){
          this.script.TagsPredefined.push(tag)
      },
      removeLastTag(){
        if (this.script.TagsPredefined.length > 0) this.script.TagsPredefined.pop();
      },
      setPredefinedTags(tags){
          this.script.TagsPredefined = tags;
      },
      getPredefinedTags(){
          return this.script.TagsPredefined;
      },
     },
}
</script>