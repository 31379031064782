<template>
    <div class="CustomTags">
        <b-row class="mt-1">
            <b-col sm="12">
                <b-form-tags
                  input-id="custom-tags"
                  v-model="result.TagsCustom"
                  tag-variant="primary"
                  tag-pills
                  size="sm"
                  separator=" "
                  :limit="limitTags"
                  :placeholder="placeHolder"
                  disabled
                  remove-on-delete
                ></b-form-tags>
            </b-col>
        </b-row>
        <b-row class="mt-1" v-if='!readOnly'>
          <b-col sm="4" size="sm" class='psm-flex'>
              <b-form-input size="sm" id="tag" :placeholder="placeHolder" v-model="inputCustomTag"></b-form-input>
              <b-button size="sm" class='ml-1' type="button" v-on:click="addTag" title='Add custom tag.'>Add</b-button>
          </b-col>
          <b-col sm="4">
            <v-select class='psm-font-small' placeholder="Select a custom tag to add...." label="Label" :options="$store.state.tagsCustom" v-model="selectedCustomTag" @input="setSelectedCustomTag" />
          </b-col>
          <b-col sm="4" size="sm">
            <div  class='float-right'>
              <b-button size="sm" class='ml-1' type="button" v-on:click="removeLastTag" title='Remove last custom tag.'>Remove last</b-button>
            </div>
          </b-col>
        </b-row>
    </div>
</template>

<script>
  export default {
    data() {
      return {
         inputCustomTag: '',
         selectedCustomTag:'',
         result:{
            TagsCustom:[]
         }
       }
    },
    props : {
       readOnly : { type : Boolean, default : false},
       limitTags : { type: Number, default : 10},
       placeHolder : { type : String, default : 'Custom tags....' }
    },
    methods:{
      addTag(){
        var tag = this.inputCustomTag.trim();
        if ( tag === '' ) return;
        this.result.TagsCustom.push(tag);
        this.inputCustomTag = '';
      },
      removeLastTag(){
        if (this.result.TagsCustom.length > 0) this.result.TagsCustom.pop();
      },
      setSelectedCustomTag(value){
        if (value.Tag.trim() === '' ) return;
        this.result.TagsCustom.push(value.Tag);
      },
      setCustomTags(tags){
          this.result.TagsCustom = tags;
      },

      getCustomTags(){
        return this.result.TagsCustom;
      }
    }
  }
</script>
<style scoped>
