<template>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="danger">
        <b-container v-if="!websiteClosed && !websiteLoading">
            <b-navbar-brand class="h5 mb-2" href="#">Navigation</b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <b-nav-item :to="'/Home'" class="h5 mb-2" active><b-icon icon="house" variant="light"></b-icon>&nbsp;Home</b-nav-item>
                <b-nav-item :to="'/Docs'" class="h5 mb-2"><b-icon icon="newspaper" variant="light"></b-icon>&nbsp;Docs</b-nav-item>
                <b-nav-item :to="'/Scripts'" class="h5 mb-2"><b-icon icon="code-slash" variant="light"></b-icon>&nbsp;Scripts</b-nav-item>
                <b-nav-item :to="'/Licensing'" class="h5 mb-2"><b-icon icon="hand-thumbs-up" variant="light"></b-icon>&nbsp;Licensing</b-nav-item>
                <b-nav-item :to="'/Downloads'" class="h5 mb-2"><b-icon icon="download" variant="light"></b-icon>&nbsp;Downloads</b-nav-item>
              </b-navbar-nav>              
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <b-avatar class="mb-2"></b-avatar>
                <b-nav-item :to="'/Profile'" class="h5 mb-2">&nbsp;{{this.$store.state.account.name}}</b-nav-item>
                <b-nav-item-dropdown right>
                  <!-- Using 'button-content' slot -->
                  <template slot="button-content" class="h5 mb-2"><em>My PSM</em></template>
                  <b-dropdown-item :to="'/Login'" v-if="!this.isLoggedIn()" href="#">Login</b-dropdown-item>
                  <b-dropdown-item v-else disabled href="#">Login</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item :to="'/Register'" v-if="!this.isLoggedIn()" href="#">Register</b-dropdown-item>
                  <b-dropdown-item v-else disabled href="#">Register</b-dropdown-item>
                  <b-dropdown-item :to="'/Verify'" v-if="!this.isLoggedIn()" href="#">Verify registration</b-dropdown-item>
                  <b-dropdown-item v-else disabled href="#">Verify registration</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item :to="'/NewScript'" v-if="this.isLoggedIn()" href="#">New script</b-dropdown-item>
                  <b-dropdown-item v-else disabled href="#">New script</b-dropdown-item>
                  <b-dropdown-item :to="'/MyScripts'" v-if="this.isLoggedIn()" href="#">My scripts</b-dropdown-item>
                  <b-dropdown-item v-else disabled href="#">My scripts</b-dropdown-item>
                  <b-dropdown-item :to="'/VersionCheck'" v-if="this.isLoggedIn()" href="#">Version checker</b-dropdown-item>
                  <b-dropdown-item v-else disabled href="#">Version checker</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item :to="'/Profile'" v-if="this.isLoggedIn()"  href="#">Profile</b-dropdown-item>
                  <b-dropdown-item v-else disabled  href="#">Profile</b-dropdown-item>
                  <b-dropdown-item href="#" v-if="this.isLoggedIn()"  @click="logOut()">Logout</b-dropdown-item>
                  <b-dropdown-item href="#" v-else disabled>Logout</b-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </b-container>
      </b-navbar>
    </div>  
</template>
<script>
export default {
    data() {
        return {
          websiteLoading : true,
          websiteClosed : true,
        }
    },
    methods: {
     logOut() {
      this.$store.commit('resetLicense');
      this.$store.commit('resetAccount');
      this.$router.push("/Login");
      console.log(this.$store.state.account);
     },
     handleWebsiteState(websiteState){
      this.websiteClosed = (websiteState === 'CLOSED');
      this.websiteLoading = false;
     },
     isLoggedIn(){
       var login = this.$store.state.account.login;
       if (login === ''){ return false;}
       return true;
     }     
    },
    created(){
      var websiteState = this.$store.state.websiteState;
      this.$store.commit('setWebsiteStateListener', this.handleWebsiteState);
      if (websiteState === 'UNKNOWN'){
        this.$getWebsiteState(this.handleWebsiteState);
      }
    }
  }
</script>