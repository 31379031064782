<template>
  <div class="Contact">
    <b-container>
        <br/> 
        <b-card>
            <b-card-header class="h5 mb-2 psm-header">Docs</b-card-header>
            <b-card-text>
              <b-row class="mt-1">
                  <b-col sm="1">
                    <p><img src="../assets/img/Dokuwiki_logo.svg" width="70" title="Dokuwiki" alt="Dokuwiki logo.svg"></p>
                  </b-col>
                  <b-col sm="6">
                    <p>The documentation is available as a Wiki.</p>
                    <div><b-link :href='contentUrl' target='_blank'>Open PSM Wiki content page.</b-link></div>
                    <div><b-link :href='introductionUrl' target='_blank'>Open PSM Wiki introduction page.</b-link></div>
                  </b-col>
                </b-row>
             </b-card-text>
        </b-card>
        <br/> 
    </b-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
          docsUrl:'',
          publicPath: process.env.BASE_URL
      }
    },
    created(){
        this.docsUrl = this.$store.state.docsUrl;
    },
    methods: {
    },
    components: {
    },
    computed:{
      contentUrl: function(){
        return this.docsUrl+':content';
      },
      introductionUrl: function(){
        return this.docsUrl+':introduction';
      },
    }
  }
</script>
<style scoped>
</style>