<template>
  <div class="VersionCheck">
    <b-container>
        <br/> 
        <b-card>
            <b-card-header class="h5 mb-2 psm-header">Version check</b-card-header>
            <b-card-text>
              <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul>
                    <b-alert variant="danger" show>
                      <li v-for="error in errors" v-bind:key="error">
                        {{ error }}
                      </li>
                    </b-alert>
                  </ul>
              </p>
                Select a component and fill in a versionnumber. In the documentation you can find how to determine the installed version of a specific component.
                <b-form @submit="onSubmit"  @reset="onReset">
                    <b-row class="mt-2">
                        <b-col sm="1" class="mt-1">
                            <label for="name">Component</label>
                        </b-col>                        
                        <b-col sm="2">
                            <b-form-select v-model="selection.selectedComponent" :options="componentOptions"></b-form-select>
                        </b-col>                        
                          <b-col sm="1" class="mt-1">
                            <label for="name">Version</label>
                        </b-col>                        
                        <b-col sm="4">
                            <b-form-input
                                id="version"
                                placeholder="Type full version number here...."
                                v-model="selection.versionNumber"
                            ></b-form-input>
                        </b-col>                        
                        <b-col sm="1">
                          <b-button type="submit" variant="primary">Submit</b-button>
                        </b-col>
                        <b-col sm="1">
                          <b-button type="reset" variant="danger">Reset</b-button>                        
                        </b-col>
                    </b-row>
                    <div v-if="stepVisible">
                      <hr/>
                      <b-row class="mt-2 ml-1 mr-1 mb-2">
                        {{stepMessage}}
                      </b-row>
                      <template v-for="item in stepVersions">
                        <b-button :key="item" size="sm" type="button" variant="primary" @click="checkSecundairComponent(item)">Check</b-button>
                        <span class="ml-2" :key="item"><b>{{secundairComponent}} version {{item}}</b></span>
                      </template>
                    </div>
                </b-form>
            </b-card-text>
        </b-card>
        <br/> 
    </b-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        errors: [],
        stepVisible : false,
        stepMessage : '',
        stepVersions : [],
        secundairComponent : '',
        shortcut : false,
        componentOptions:[
          { value: 'Dashboard', text: 'Dashboard' },
          { value: 'Api', text: 'API' },
          { value: 'Service', text: 'Service' },
          { value: 'Agent', text: 'Agent' },
          { value: 'Database', text: 'Database' }        ],
        selection : { selectedComponent :  '', versionNumber : ''}
      }
    },
    methods: {
      //#region regular events
      onSubmit(event) {
        event.preventDefault();
        this.validate();
        this.submit();
      },
      callbackOKSubmit(response){
        console.log(response);
        this.errors = [];
        this.stepVisible = true;
        if (response.data && response.data.Data)
        {
          var len = response.data.Data.length;
          if (len === 0){
            if (this.shortcut){
              this.$swal("Check Version", "No result, component is not depending on another component.", "info");            
              this.shortcut = false;
            }
            else
              this.$swal("Error checking version", "No result, check the version number.", "error");            
          }
          else{
            var i;
            for (i=0; i < len; i++)
            {
              this.stepVersions.push(response.data.Data[i].ComponentSecundairVersion);
            }
          }
        }
      },
      onReset(event){
        event.preventDefault();
        this.reset();
      },
      //#endregion
      //#region local methods 
      validate: function () {
        this.errors = [];
        if (this.selection.selectedComponent == '') this.errors.push('Component is required.');
        if (this.selection.versionNumber == '') this.errors.push('Version number is required.');
      },
      reset(){
        this.errors=[];
        this.stepVisible = false;
        this.stepMessage = '';
        this.stepVersions = [];
        this.selection.selectedComponent = '';
        this.selection.versionNumber = '';
        this.shortcut = false;
      },
      submit(){
        if (this.errors.length == 0){

          if (this.selection.selectedComponent === 'Dashboard'){
            this.stepMessage = 'The Dasboard component depends on the Api component. Check if the present Api component version is in the list. If not you have to upgrade the Api if the required version is higher then the present version. Otherwise upgrade the Dashboard.';
            this.secundairComponent = 'Api';
          }
          if (this.selection.selectedComponent === 'Api'){
            this.stepMessage = 'The Api component depends on the database. Check if the present database version is in the list. If not you have to upgrade the database if the required version is higher then the present version. Otherwise upgrade the Api.';
            this.secundairComponent = 'Database';
          }
          if (this.selection.selectedComponent === 'Service'){
            this.stepMessage = 'The Service component depends on the database. Check if the present database version is in the list. If not you have to upgrade the database if the required version is higher then the present version. Otherwise upgrade the Service.';
            this.secundairComponent = 'Database';
          }
          if (this.selection.selectedComponent === 'Agent'){
            this.stepMessage = 'The Agent component depends on the Service component. Check if the present Service component version is in the list. If not you have to upgrade the Service if the required version is higher then the present version. Otherwise upgrade the Agent.';
            this.secundairComponent = 'Service';
          }

          var parm = { Component : this.selection.selectedComponent , Version :this.selection.versionNumber, SecundaireComponent : this.secundairComponent };
          this.$restPost('/backoffice/checkversion', parm, this.callbackOKSubmit, 'Error version checking.');
        }
      },
      checkSecundairComponent(item){
        this.reset();
        this.shortcut = true;
        this.selection.selectedComponent = this.secundairComponent;
        this.selection.versionNumber = item;
        this.submit();
      }
      //#endregion
    },
    components: {
    },
    computed:{
      }
}
</script>
<style scoped>
</style>