<template>
  <div class="Contact">
    <b-container>
        <br/> 
        <b-card>
            <b-card-header class="h5 mb-2 psm-header">Contact</b-card-header>
            <b-card-text>
              <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul>
                    <b-alert variant="danger" show>
                      <li v-for="error in errors" v-bind:key="error">
                        {{ error }}
                      </li>
                    </b-alert>
                  </ul>
              </p>
                Fill in the form below and, if desired, we will try to contact you as soon as possible.
                <b-form @submit="onSubmit" @reset="onReset">
                    <b-row class="mt-2">
                        <b-col sm="2">
                        <label for="name">Your name:</label>
                        </b-col>                        
                        <b-col sm="10">
                        <b-form-input
                            id="name"
                            placeholder="Type your name here...."
                            v-model="contact.name"
                        ></b-form-input>
                        </b-col>
                    </b-row>
                   <b-row class="mt-2">
                        <b-col sm="2">
                        <label for="contacttype">Type:</label>
                        </b-col>                        
                        <b-col sm="10">
                          <b-form-group id="contacttype">
                            <b-form-radio v-model="contact.contacttype" name="contacttype-radio" value="Request">Request</b-form-radio>
                            <b-form-radio v-model="contact.contacttype" name="contacttype-radio" value="Question">Question</b-form-radio>
                            <b-form-radio v-model="contact.contacttype" name="contacttype-radio" value="Complaint">Complaint</b-form-radio>
                            <b-form-radio v-model="contact.contacttype" name="contacttype-radio" value="Other">Other</b-form-radio>
                          </b-form-group>
                        </b-col>
                    </b-row>
                   <b-row class="mt-2">
                        <b-col sm="2">
                        <label for="message">Your message, request, question or complaint:</label>
                        </b-col>                        
                        <b-col sm="10">
                        <b-form-textarea
                            id="message"
                            placeholder="Type your message, request, question or complaint here...."
                            v-model="contact.message"
                        ></b-form-textarea>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col sm="2">
                        <label for="name">Your contact info:</label>
                        </b-col>                        
                        <b-col sm="10">
                        <b-form-input
                            id="contact"
                            placeholder="How can we contact you. Type here your email address, mobile number or the like.... Leave empty if no feedback required."
                            v-model="contact.contactinfo"
                        ></b-form-input>
                        </b-col>
                    </b-row>
                    <br/>                   
                    <b-row>
                      <b-col sm="2"></b-col>                        
                      <b-col sm="10">
                        <vue-hcaptcha v-bind:sitekey="sitekey" 
                            theme="light"
                            @verify="onCallback"
                            @expired="onExpire"
                            @error="onError" ></vue-hcaptcha>
                      </b-col>                        
                    </b-row>
                    <br/>                   
                    <b-button type="submit" variant="primary" :disabled="isDisabled">Submit</b-button>
                    &nbsp;<b-button type="reset" variant="danger">Reset</b-button>
                </b-form>
            </b-card-text>
        </b-card>
        <br/> 
    </b-container>
  </div>
</template>

<script>
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
  export default {
    data() {
      return {
        sitekey:this.$root.hcaptchaSitekey,
        hcaptchaVerified : !this.$root.hcaptchaEnabled,
        errors: [],
        contact: {
          name: '',
          message: '',
          contactinfo: '',
          contacttype:'',
          login:'',
          clientip:this.$root.ip
        }
      }
    },
    methods: {
      //#region hcaptcha events
      onCallback(response) {
          this.hcaptchaVerified = true;
          console.log(`hCaptcha Callback Response: ${response}`);
        },
      onExpire() {
        this.hcaptchaVerified = false;
        console.log("hCaptcha Expired");
      },
      onError(err) {
        console.log(`hCaptcha Error: ${err}`);
      },
      //#endregion
      //#region regular events
      onSubmit(event) {
        event.preventDefault()
        this.validate();
        if (this.errors.length == 0){
          this.$restPost('/backoffice/contact/add', this.contact, this.callbackOKSubmit, 'Error sending the contact information.' );
        }
      },
      callbackOKSubmit(){
        this.$swal("Thank you for your message!", "We will contact you if necessary.", "success");
        this.reset();
      },
      onReset(event) {
        event.preventDefault();
        this.reset();
      },
      //#endregion
      //#region local methods 
      validate: function () {
        this.errors = []
        if (this.contact.name == '' || this.contact.message == '' || this.contact.contactinfo == '' || this.contact.contacttype == '')
          this.errors.push('All fields are required.');
      },
      reset: function(){
        // Reset our form values
        this.contact.name = ''
        this.contact.message = ''
        this.contact.contactinfo = ''
        this.contact.contacttype = ''
      }
      //#endregion
     
    },
    components: {
      VueHcaptcha
    },
    computed:{
      isDisabled(){
        return !this.hcaptchaVerified;
      }
    }
  }
</script>
<style scoped>
</style>