<template>
  <div class="Home">
    <b-container class='mt-4' v-if="websiteLoading" style="text-align:center">
      <b-jumbotron  header="PSM Website loading.">
      <p><font-awesome-icon icon="spinner" size="4x" style="color:red" spin/></p>
      </b-jumbotron>
    </b-container>    

    <b-container v-if="!websiteClosed && !websiteLoading">
        <br/> 
        <b-card>
            <b-card-header class="h5 mb-2 psm-header">Why?</b-card-header>
            <b-card-text>
            <i>Where do I have that script? <br/>Did I install the script on that server? <br/>Isn't there a newer version of this script?<br/>Did the script run correctly? <br/>Am I in control or what?<br/>
            Why wasn't I informed of that error?<br/><br/></i>
            If you ask yourself these questions to often, then you should definitely try the PSM suite.
            </b-card-text>
        </b-card>
        <br/> 
        <b-card>
            <b-card-header  class="h5 mb-2 psm-header">What is it?</b-card-header>
            <b-card-text>
              PSM is a set of collaborating software components that enables you to run and schedule scripts from a centralized server on any remote server. 
              It is cross platform, it supports both Linux and Windows servers. This applies to both installing the components as running the scripts. 
              It is not bound to a specific script language, it is even possible to run SQL scripts against a database. For its repository it uses SQLite by default 
              but scripts for other popular databases are available. <br/><br/>

              <p class="h6 mb-2">Notifications</p>
              It is possible to send notifications by email or SMS. An interface with 
              <img src="../assets/img/bootstrap-icons/telegram.svg" alt="" width="16" height="16" title="Telegram"> Telegram is also available for notifications.<br/>
              You are fully in control of when to send notifications and about the content of the notification.<br/><br/>

              <p class="h6 mb-2">Dashboard</p>
              A Dashboard is available to keep you real-time informed of errors, warnings and such. 
              There are statistics to provide insight into trends. Is it going better or worse?
              The Dashboard is also used to configure the software.<br/><br/>
              <b-button href="#"  @click="toggleShowExample" variant="danger" v-if="!showExample">Example</b-button>  
              <b-button href="#"  @click="toggleShowExample" variant="danger"  v-if="showExample">Hide Example</b-button> 
              <b-col sm="12" v-if="showExample" class="mt-2" v-on:mouseover="setExampleLight" v-on:mouseleave="setExampleDark">
                  <img v-if="exampleDark" src="../assets/img/dashboard-dark.png" width="90%" title="Example dark mode" alt="Example">'
                  <img v-if="exampleLight" src="../assets/img/dashboard-light.png" width="90%" title="Example light mode" alt="Example">'
              </b-col>
            </b-card-text>
        </b-card>  
        <br/> 
        <b-card>
            <b-card-header class="h5 mb-2 psm-header">Why not?</b-card-header>
            <b-card-text>
            If you only have a limited number of servers the software is free to install on one central server. 
            You can also 'earn' extra servers by uploading your scripts for public use.
            The free version is bound to use an SQLite database. You should switch to a payed license if ... <br/>
            ..... you have to install it on another DBMS.  <br/>
            ..... you are in need for more then one installation.<br/>
            ..... the number of free licensed servers is to small.<br/>
            <br/>
            <b-button href="#" variant="danger" :to="'/Licensing'">Licensing</b-button>
            </b-card-text>
        </b-card> 
        <br/> 
        <b-card>
            <b-card-header class="h5 mb-2 psm-header">Who is it for?</b-card-header>
            <b-card-text>
            The software is intended for system administrators, database administrators, DevOps engineers, scripters, operators and more. 
            You must be capable of writing your own scripts or customizing scripts from the available repository. 
            Basic knowledge of networking and security is recommended.<br/><br/>
            <b-button href="#" variant="danger" :to="'/Scripts'">Scripts</b-button>             
            <br/><br/>
            <p class="h6 mb-2">Warning</p>
            Using the software is not fool proof. You have to do quite a bit configuration befor it runs the first time. <br/><br/>
            <b-button href="#" variant="danger" :to="'/Docs'">Docs</b-button>            
            </b-card-text>
        </b-card>          
        <br/> 
    </b-container>
    <b-container class='mt-4' v-if="websiteClosed && !websiteLoading">
      <b-jumbotron  header="Website closed." :lead=closedMessage>
      <p><font-awesome-icon icon="sitemap" style="color:red" @dblclick="handleDblClick"/>&nbsp;&nbsp;{{closedSubmessage}}</p>
      </b-jumbotron>
      <b-form @submit="onSubmit"  v-if="showKey">
        <b-row class="mt-2 mb-4">
          <b-col sm="2" class="mt-2">
            <label for="name">Key to open website:</label>
          </b-col>                        
          <b-col sm="4">
            <b-form-input
              id="name"
              type="password"
              placeholder="Type the key here...."
              v-model="openKey"
            ></b-form-input>
          </b-col>
          <b-col sm="2">
            <b-button type="submit" variant="primary">Submit</b-button>          
          </b-col>
        </b-row>
      </b-form>
    </b-container>    
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      showKey : false,
      websiteLoading : true,
      websiteClosed : true,
      closedMessage : '',
      closedSubmessage : '',
      openKey:'',
      showExample:false,
      exampleDark:true,
      exampleLight:false,
    }
  },
  methods: {
    toggleShowExample(){
        this.showExample = !this.showExample;
      }, 
    setExampleLight()
    {
      this.exampleDark = false;
      this.exampleLight = true;
    },
    setExampleDark()
    {
      this.exampleDark = true;
      this.exampleLight = false;
    },
    handleWebsiteState(websiteState){
      this.websiteClosed = (websiteState === 'CLOSED');
      this.websiteLoading = false;
      if (this.websiteClosed){
        if (this.closedMessage === '' ){
        // Get dynamic 'maintenance'text.
        var parm = [ {Key : 'WEBSITE-CLOSED-MESSAGE'},{ Key : 'WEBSITE-CLOSED-SUBMESSAGE'} ];
        this.$restPost('/backoffice/getsettings', parm, this.callbackOKHandleWebsiteState, 'Error sending the setting keys.');
        }
      }
    },       
    callbackOKHandleWebsiteState(response){
      this.closedMessage = response.data.Data.find(x => x.Key === 'WEBSITE-CLOSED-MESSAGE').Value;        
      this.closedSubmessage = response.data.Data.find(x => x.Key === 'WEBSITE-CLOSED-SUBMESSAGE').Value;        
    },
    handleDblClick(){
      this.showKey = true;
      },
    onSubmit(event) {
        event.preventDefault()
        var parm = { Value : this.openKey};
        this.$restPost('/backoffice/checkopenkey', parm, this.callbackOKSubmit, 'Error sending the open key.' );
        }, 
    callbackOKSubmit(response){
      if (response.data.Data.Value === true)
        {
          this.websiteClosed = false;
          this.$store.commit('setWebsiteState', 'OPEN');
         }
        else{
          this.$swal("Incorrect key.","The supplied value is not correct.", "error");
        }
      }
    },
    created(){
      var websiteState = this.$store.state.websiteState;
      this.$store.commit('setWebsiteStateListener', this.handleWebsiteState);
      if (websiteState === 'UNKNOWN'){
        this.$getWebsiteState(this.handleWebsiteState);
      }
      if (websiteState === 'OPEN'){
        this.websiteLoading = false;
        this.websiteClosed = false;
      }
    }
};
</script>
<style scoped>
</style>