<template>
  <div class="Script">
        <b-card  v-if="elements.componentVisible">
            <b-card-header class="h6 mb-1 psm-header">
                <span @click="toggleVisibilityCard" class="psm-cursor-pointer">
                  <b-icon-caret-down-fill v-if="!elements.cardVisible" class="h6 mb-1" variant="light"/>
                  <b-icon-caret-up-fill   v-if="elements.cardVisible" class="h6 mb-1" variant="light"/>
                </span>
                &nbsp;Script
                <p class="float-sm-right psm-cursor-pointer"><CloseParent @close='closeThis'/></p>
            </b-card-header>
            <b-card-text v-if="elements.cardVisible" >
              <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul>
                    <b-alert variant="danger" show>
                      <li v-for="error in errors" v-bind:key="error">
                        {{ error }}
                      </li>
                    </b-alert>
                  </ul>
              </p>
                <b-form @submit="onSubmit">
                  <b-row class="mt-2">
                    <b-col sm="2">
                       <label for="key">Script key:</label>
                    </b-col>                        
                    <b-col sm="4">
                      <b-form-input id="key" type="text" :title="script.key" v-model="script.key" :disabled="true"/>
                    </b-col> 
                    <b-col sm="1">
                      <CopyClipboard v-bind:valueToCopy=script.key title="Copy script key to clipboard."/>
                    </b-col>
                    <b-col sm="1">
                       <label for="version">Version:</label>
                    </b-col>                        
                    <b-col sm="1">
                      <b-form-input id="version" type="text" v-model="paddedVersion" :disabled="true"/>
                    </b-col> 
                  </b-row>
                  <b-row class="mt-2">
                    <b-col sm="2">
                       <label for="name">Name:</label>
                    </b-col>                        
                    <b-col sm="10">
                      <b-form-input id="name" type="text" v-model="script.Name" placeholder="Give the script an identifying name..." :disabled="readonly"/>
                    </b-col> 
                  </b-row>
                  <b-tabs card class="mt-2" content-class="mt-2" v-model="tabIndex">
                       <b-tab title="Properties" active>
                         <b-row class="mb-2">
                           <b-col sm="2">Description:</b-col>
                           <b-col sm="10">
                              <b-form-textarea
                              id="Description"
                              placeholder="Type the description of the script here. What does it, how does it do it...."
                              v-model="script.Description"
                              rows="2"
                              :disabled="readonly"
                              ></b-form-textarea>
                            </b-col>
                          </b-row>
                          <b-row class="mb-2">
                            <b-col sm="2">Usage:</b-col>
                            <b-col sm="10">
                                <b-form-textarea
                                id="Usage"
                                placeholder="Type how to use the script here. Think of values of used variables, constants and such ...."
                                v-model="script.Usage"
                                rows="2"
                                :disabled="readonly"
                                ></b-form-textarea>
                            </b-col>
                          </b-row>
                          <b-row class="mb-2">
                            <b-col sm="2">Requirements:
                            </b-col>
                            <b-col sm="10">
                                <b-form-textarea
                                id="Requirements"
                                placeholder="Type here what requirements the script needs. Think of installed packages, specific versions and such...."
                                v-model="script.Requirements"
                                rows="2"
                                :disabled="readonly"
                                ></b-form-textarea>
                            </b-col>
                          </b-row>
                       </b-tab>
                       <b-tab title="Script">
                          <b-row class="mb-2">
                            <b-col sm="4">
                              <TextReader @load="script.Content = $event" ></TextReader>
                            </b-col>
                            <b-col sm="6">
                            </b-col>
                            <b-col sm="2">
                              <span>{{scriptLength}} chars
                              <CopyClipboard v-bind:valueToCopy=script.Content title="Copy script content to clipboard."/></span>
                            </b-col>
                          </b-row>
                          <b-row class="mb-2">
                            <b-col>
                              <b-form-textarea
                              id="Content"
                              placeholder="Type or paste your script here...."
                              v-model="script.Content"
                              rows="15"
                              :disabled="readonly"
                              ></b-form-textarea>
                            </b-col>
                          </b-row>
                       </b-tab>
                       <b-tab title="Tags">
                         <b-row class="mt-2" v-if="readonly === false">
                           <b-col sm="12">
                             Bind tags to the script so other users can find it easily. Select from the dropdowns the predefined tags. 
                             In the lower part you can add your own tags. Once in a while we analyze the custom tags and we will promote the most common
                             to predefined. 
                           </b-col>
                         </b-row>
                         <PredefinedTags ref='PredefinedTags' place-holder='Predefined tags....'/>
                         <CustomTags ref='CustomTags' place-holder='Custom tags...' />
                         </b-tab> 
                  </b-tabs>  
                  <b-button class="ml-4" type="submit" variant="primary" v-if="!readonly">Submit</b-button>
                  <b-button class="ml-2" type="button" variant="primary" v-if="!readonly" v-on:click="deleteScript">Delete</b-button>
                </b-form>
            </b-card-text>
        </b-card>
  </div>
</template>

<script>
  import CloseParent from './parts/CloseParent';
  import CopyClipboard from './parts/CopyClipboard';
  import PredefinedTags from './parts/PredefinedTags';
  import CustomTags from './parts/CustomTags';
  export default {
    data() {
      return {
         tabIndex:0,
         errors: [],
         readonly:true,
         elements:{
           componentVisible:true,
           cardVisible: true
         },
         previousScriptContent : '',
         script : {
            customerId:0,
            login:'',
            token:'',
            id : 0,
            key : '',
            version : 1,
            Name : '',
            Content : '',
            Description : '',
            Usage:'',
            Requirements:'',
            TagsPredefined:[],
            TagsCustom:[]
         },
      }
    },
    created(){
        this.script.login = this.$store.state.account.login;
        this.script.customerId = this.$store.state.account.id;
        this.script.token = this.$store.state.account.token;
        this.script.key = this.$getGuid();
        this.errors = [];
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.script.TagsPredefined = this.$refs.PredefinedTags.getPredefinedTags();
        this.script.TagsCustom = this.$refs.CustomTags.getCustomTags();
        this.validate();
        if (this.errors.length == 0){
          if (this.previousScriptContent !== this.script.Content)
          {
            // Script changed.
            this.script.version = this.script.version + 1;
          }          
          this.$restPost('/backoffice/script/update', this.script, this.callbackOKSubmit, 'Error sending the script.' );
        }
      },
      callbackOKSubmit(response){
        this.script.id = response.data.Id;
        var msg =  "The script was saved succesfully!.";
        if (this.previousScriptContent !== this.script.Content)
        {
          // Script changed.
          msg = "The script was saved succesfully, the script content changed so the version number increased.";
          this.previousScriptContent = this.script.Content;
        }
        this.$swal("Saving the script", msg, "success");
      },
      validate: function () {
        this.errors = [];
        if (this.script.TagsPredefined.length == 0) 
        {
          this.errors.push('At least one predefined tag should be added.');
          this.tabIndex = 2;
        }
        if (this.script.Content == '') 
        {
          this.errors.push('Script content is required.');
          this.tabIndex = 1;
        }
        if (this.script.Name == '') 
        {
          this.errors.push('Name is required.');
        }
        if (this.script.Description == ''){
          this.errors.push('Description is required.');
          this.tabIndex = 0;
       }
        if (this.script.Usage == ''){
          this.errors.push('Usage is required.');
          this.tabIndex = 0;
        }
        if (this.script.Requirements == '') {
          this.errors.push('Requirements is required.');
          this.tabIndex = 0;
        }
       },
      deleteScript(){
        this.$swal.fire({
          title: 'Are you sure to delete this script?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (!result.dismiss || result.dismiss !== 'cancel') {
            this.$restPost('/backoffice/script/delete', this.script, this.callbackDelete, 'Error deleting the script.' );
          }
        })
      },
      callbackDelete(){
        var msg =  "The script was deleted succesfully!.";
        this.$swal("Deleting the script", msg, "success");
        // Remove from array.
        this.$parent.deleteScript(this.script.key);
        // Reset screen.
        this.resetFields();
        this.setReadOnly(true);
      },
      toggleVisibilityCard(){
        this.elements.cardVisible = !this.elements.cardVisible;
      },
      setVisibilityComponent(visible)
      {
        this.elements.componenVisible = visible;
      },
      addPredefinedTag(tag){
          this.script.TagsPredefined.push(tag);
      },
      closeThis(){
        this.elements.componentVisible = false;
      },
      loadScriptDetails(key){
        this.errors = [];
        this.elements.componentVisible = true;
        this.script.key = key;
        var parm =  { Value : key};
        this.$restPost('/backoffice/script/getbykey', parm, this.callbackLoadScriptDetails, 'Error getting scripts.');
      },
      callbackLoadScriptDetails(response){
        //#region Set data
        this.script.id = response.data.Data.Id;
        this.script.version = response.data.Data.Version;
        this.script.Name = response.data.Data.Name;
        this.script.Description = response.data.Data.Description;
        this.script.Content = response.data.Data.Content;
        // To check changes.
        this.previousScriptContent =  response.data.Data.Content;
        this.script.Usage = response.data.Data.Usage;
        this.script.Requirements = response.data.Data.Requirements;
        this.$refs.PredefinedTags.setPredefinedTags(response.data.Data.PredefinedTags.split(';'));
        this.$refs.CustomTags.setCustomTags(this.script.TagsCustom = response.data.Data.CustomTags.split(';'));
        //#endregion
      },
      setReadOnly(readonly){
        this.readonly = readonly;
      },
      resetFields(){
        this.script = {
            customerId:0,
            login:'',
            token:'',
            id : 0,
            key : '',
            version : 1,
            Name : '',
            Content : '',
            Description : '',
            Usage:'',
            Requirements:'',
            TagsPredefined:[],
            TagsCustom:[] }
      },
    },
    components: {
      CloseParent, CopyClipboard, PredefinedTags, CustomTags
    },
    computed:{
        paddedVersion :  { get() {
            var s = "000" + this.script.version;
            return s.substr(s.length-3);
            }
        },
        scriptLength : { get() {
            return this.script.Content.length;
          }
        },
    },
  }
</script>
<style scoped>

</style>