<template>
    <div class="psm-header">
        <b-container class="p-4">
        <b-row>
            <b-col>
            <img left src="../assets/img/psm-logo.png" alt="PSM logo"/>
            </b-col>
            <b-col>
            <img center src="../assets/img/header-logo.png" alt="Pragmatic Script Master - Control your scripts"/>
            </b-col>
            <b-col>
                <b-carousel right
                    id="carousel-no-animation"
                    style="text-shadow: 0px 0px 2px #000"
                    img-width="300"
                    img-height="100"
                    fade
                >
                    <b-carousel-slide
                    :img-src="`${publicPath}static/header-caroussel/script-1.png`"
                    ></b-carousel-slide>
                    <b-carousel-slide
                    :img-src="`${publicPath}static/header-caroussel/bash.png`"
                    ></b-carousel-slide>
                    <b-carousel-slide
                    :img-src="`${publicPath}static/header-caroussel/script-2.png`"
                    ></b-carousel-slide>
                    <b-carousel-slide
                    :img-src="`${publicPath}static/header-caroussel/php.png`"
                    ></b-carousel-slide>
                    <b-carousel-slide
                    :img-src="`${publicPath}static/header-caroussel/script-4.png`"
                    ></b-carousel-slide>
                    <b-carousel-slide
                    :img-src="`${publicPath}static/header-caroussel/powershell.png`"
                    ></b-carousel-slide>
                    <b-carousel-slide
                    :img-src="`${publicPath}static/header-caroussel/script-3.png`"
                    ></b-carousel-slide>
                    <b-carousel-slide
                    :img-src="`${publicPath}static/header-caroussel/python.png`"
                    ></b-carousel-slide>
                </b-carousel>                
            </b-col>
        </b-row>
        </b-container>
    </div>  
</template>
<script>
export default {
    data () {
    return {
        publicPath: process.env.BASE_URL
        }
    }  
}    
</script>