<template>
  <div class="MyScripts">
    <b-container>
        <ScriptsTable ref='scriptList' class='mt-1' cardTitle='My scripts' @selectScript='selectScript'/>
        <Script ref='scriptDetails' read-only="false"/>
    </b-container>
  </div>
</template>

<script>
  import ScriptsTable from './parts/ScriptsTable';
  import Script from './Script.vue';  
  export default {
    data() {
      return {
         tabIndex:0,
         errors: [],
      }
    },
    methods: {
      selectScript(scriptKey){
        this.errors = [];
        this.$refs.scriptDetails.loadScriptDetails(scriptKey);
        this.$refs.scriptDetails.setVisibilityComponent(true);
        this.$refs.scriptDetails.setReadOnly(false);
      },
      deleteScript(key){
        this.$refs.scriptList.delete(key);
      }
     },
    components: {
        ScriptsTable, Script
    },
  }
</script>
<style scoped>

</style>