<template>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="danger">
        <b-container  v-if="!websiteClosed && !websiteLoading">
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <b-nav-item :to="'/About'" class="h5 mb-2"><b-icon icon="chat-right-text" variant="light"></b-icon>&nbsp;About</b-nav-item>
                <b-nav-item :to="'/Contact'" class="h5 mb-2"><b-icon icon="mailbox" variant="light"></b-icon>&nbsp;Contact</b-nav-item>
              </b-navbar-nav>              
            </b-collapse>
            <p class="h5 mb-2" >Copyright © <a href="https://www.pragmatic.nl" style="color: black">Pragmatic 2021, version {{ version }}</a></p>
          </b-container>
      </b-navbar>
    </div>  
</template>

<script>
  export default {
    data() {
      return {  
        version: process.env.VUE_APP_VERSION,
        websiteClosed : true,
        websiteLoading : true
      }
    },
    methods: {
     handleWebsiteState(websiteState){
        this.websiteClosed = (websiteState === 'CLOSED');
        this.websiteLoading = false;
       }       
    },
    created(){
      var websiteState = this.$store.state.websiteState;
      this.$store.commit('setWebsiteStateListener', this.handleWebsiteState);
      if (websiteState === 'UNKNOWN'){
        this.$getWebsiteState(this.handleWebsiteState);
      }
    }
  }
</script>