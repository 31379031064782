<template>
  <div class="License">
    <b-container>
        <br/> 
        <b-card>
            <b-card-header class="h6 mb-1 psm-header">
                <span @click="toggleVisibilityCard" class="psm-cursor-pointer">
                  <b-icon-caret-down-fill v-if="!elements.cardVisible" class="h6 mb-1" variant="light"/>
                  <b-icon-caret-up-fill   v-if="elements.cardVisible" class="h6 mb-1" variant="light"/>
                </span>
                &nbsp;License
            </b-card-header>
            <b-card-text v-if="elements.cardVisible" >
              <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul>
                    <b-alert variant="danger" show>
                      <li v-for="error in errors" v-bind:key="error">
                        {{ error }}
                      </li>
                    </b-alert>
                  </ul>
              </p>
                <b-form @submit="onSubmit">
                  <b-row v-if="!this.hasLicense">
                    <b-col sm="5">
                      You don't have a license yet, you should get one 😊 ...
                    </b-col>
                    <b-col sm="2">
                      <b-form-select v-model="selectedLicenseType" :options="licenseTypeOptions"></b-form-select>
                    </b-col>
                    <b-col>
                      <b-button type="button" variant="primary" v-on:click="getNewLicense" :disabled="hasLicense">Get license</b-button>
                    </b-col>
                  </b-row>
                  <div v-if="this.hasLicense" >                  
                    <b-row class="mt-2">
                      <b-col sm="2">
                        <label for="license">Your license:</label>
                      </b-col>                        
                      <b-col sm="9">
                        <b-form-textarea id="license" v-model="this.licenseContent" :disabled="true" rows="4" />
                      </b-col>
                      <b-col sm="1">
                        <CopyClipboard v-bind:valueToCopy="licenseContent" title="Copy license to clipboard."/>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col sm="2">
                        <label for="licenseType">Type:</label>
                      </b-col>                        
                      <b-col sm="4">
                        <b-form-input id="licenseType" v-model="this.licenseType" :disabled="true"/>
                      </b-col>
                      <b-col sm="2">
                        <label for="validated">Last validation:</label>
                      </b-col>                        
                      <b-col sm="3">
                        <b-form-input id="validated" v-model="this.licenseValidated" :disabled="true"/>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col sm="2">
                        <label for="productkey">Productkey:</label>
                      </b-col>                        
                      <b-col sm="4">
                        <b-form-input id="productkey" v-model="this.licenseProductkey" :disabled="true"/>
                      </b-col>
                      <b-col sm="2">
                        <label for="expires">Expires:</label>
                      </b-col>                        
                      <b-col sm="3">
                        <b-form-input id="expires" v-model="this.licenseExpires" :disabled="true"/>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col sm="2">
                        <label>Upgrade to :</label>
                      </b-col> 
                      <b-col sm="2">
                        <b-button type="button" variant="primary" v-on:click="upgradeToStandard" :disabled="!this.upgradeableToStandard">Standard</b-button>
                      </b-col>
                      <b-col sm="2">
                        <b-button type="button" variant="primary" v-on:click="upgradeToEnterprise" :disabled="!this.upgradeToEnterprise">Enterprise</b-button>
                      </b-col>
                      <b-col sm="2">
                        <label>{{expirationMessage}}</label>
                      </b-col>                        
                      <b-col sm="2">
                        <b-button type="button" variant="primary" v-on:click="renewLicense" :disabled="!this.renewable">Renew</b-button>
                      </b-col>
                     </b-row>
                  </div>  
                </b-form>
            </b-card-text>
        </b-card>
    </b-container>
  </div>
</template>

<script>
  import CopyClipboard from './CopyClipboard.vue';
  export default {
    data() {
      return {
        errors: [],
        elements:{
           cardVisible: false
        },
        selectedLicenseType:1,
        licenseTypeOptions:[
          { value: 1, text: 'Free license' },
          { value: 2, text: 'Standard license' },
          { value: 3, text: 'Business license' }
        ]
      }
    },
    created(){
      // Lazy load and put in state.
      if (!this.$store.state.license.retrieved)
      {
        this.getLicense();
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
 
      },
      toggleVisibilityCard(){
        this.elements.cardVisible = !this.elements.cardVisible;
      },
      copyLicenseToClipboard(){
          this.$clipboard(this.licenseContent);
      },
      getNewLicense(){
        var parm =  { login : this.$store.state.account.login, token : this.$store.state.account.token, licenseType : this.selectedLicenseType};
        this.$restPost('/backoffice/license/createcustomerlicense', parm, this.callbackOKNewLicense, 'Error sending the new license request.');
      },
      callbackOKNewLicense(response){
        var licenseType = this.$translateLicenseType(response.data.Data.LicenseType);
        var expires = this.$formatDate(response.data.Data.Expires);
        var validated = this.$formatDate(response.data.Data.Validated);
        var license = { retrieved : true, hasLicense : true, content : response.data.Data.Content, licenseType : licenseType, licenseTypeNr : response.data.Data.LicenseType,
          valid : response.data.Data.Valid, productKey : response.data.Data.ProductKey, validated : validated, validatedDt : response.data.Data.Validated,
          expires : expires, expiresDt : response.data.Data.Expires };
        this.$store.commit('setLicense', license);
      },
      getLicense(){
        var parm =  { customerId : this.$store.state.account.id, token : this.$store.state.account.token};
        this.$restPost('/backoffice/license/getcustomerlicense', parm, this.callbackOKLicense, 'Error sending the license request.');
      },
      callbackOKLicense(response){
        var license = { retrieved : true, hasLicense : false};
        if (response.data.Data.HasLicense)
        {
          var licenseType = this.$translateLicenseType(response.data.Data.LicenseType);
          var expires = this.$formatDate(response.data.Data.Expires);
          var validated = this.$formatDate(response.data.Data.Validated);
          license = { retrieved : true, hasLicense : true, content : response.data.Data.Content, licenseType : licenseType, licenseTypeNr : response.data.Data.LicenseType,
          valid : response.data.Data.Valid, productKey : response.data.Data.ProductKey, validated : validated,  validatedDt : response.data.Data.Validated,
          expires : expires, expiresDt : response.data.Data.Expires};
        }
        this.$store.commit('setLicense', license);
      },
      upgradeToStandard(){
        const STANDARDLICENSE = 2;
        this.$swal.fire({
              title: 'Upgrade to standard license!',
              text: 'You know that a standard license is not free? You will receive an invoice.',
              type: 'question',
              showCancelButton : true
            }).then((response) => {
              if (response.dismiss && response.dismiss == 'cancel')
                return;
              this.doUpgrade(STANDARDLICENSE);
            });
      },
      upgradeToEnterprise(){
        const ENTERPRISELICENSE = 3;
        this.$swal.fire({
              title: 'Upgrade to enterprise license!',
              text: 'You know that a enterprise license is not free? You will receive an invoice.',
              type: 'question',
              showCancelButton : true
            }).then((response) => {
              if (response.dismiss && response.dismiss == 'cancel')
                return;
              this.doUpgrade(ENTERPRISELICENSE);
            });
      },
      doUpgrade(licenseType){
        var parm =  { login : this.$store.state.account.login, token : this.$store.state.account.token, licenseType : licenseType};
        this.$restPost('/backoffice/license/upgrade', parm, this.callbackOKUpgradeLicense, 'Error upgrading the license.');
      },
      callbackOKUpgradeLicense(){
        this.getLicense();
        this.$swal("Thank you for upgrading the license.","Copy the license to your PSM installation(s).", "success");
      },
      renewLicense(){
         var parm =  { login : this.$store.state.account.login, token : this.$store.state.account.token, licenseType : 0};
        this.$restPost('/backoffice/license/renew', parm, this.callbackOKRenewLicense, 'Error renewing the license.' );
      },
      callbackOKRenewLicense(){
        this.getLicense();
        this.$swal("Thank you for renewing the license.","Copy the license to your PSM installation(s).", "success");
      }
    },
    components: {
      CopyClipboard
    },
    computed:{
      licenseRetrieved :  { get() {return this.$store.state.license.retrieved}},
      hasLicense :  { get() {return this.$store.state.license.hasLicense}},
      licenseProductkey : { get() {return this.$store.state.license.productKey}},
      licenseContent : { get() {return this.$store.state.license.content}},
      licenseType : { get() {return this.$store.state.license.licenseType}},
      licenseValid : { get() {return this.$store.state.license.valid}},
      licenseValidated : { get() {return this.$store.state.license.validated}},
      licenseExpires : { get() {return this.$store.state.license.expires}},
      upgradeableToStandard : { get()
        {
          if (this.$store.state.license.licenseTypeNr === 1) return true;
          return false;
        },
      },
      upgradeableToEnterprise : { get()
        {
          if (this.$store.state.license.licenseTypeNr === 3) return false;
          return true;
        }
      },
      renewable : { get()
        {
          if(this.expirationDays < 60) return true;
          return false;
        }
      },
      expirationDays : { get()
        {
          var expirationDate = new Date(this.$store.state.license.expiresDt);
          var currentDate = new Date();
          var days = this.$differenceInDays(currentDate, expirationDate);
          return days;
        }
      },
      expirationMessage : { get()
        {
          if (this.expirationDays < 0 ) return "License is expired";
          return "Expiring in " + this.expirationDays + " days";
        }
      }
    }
  }
</script>
<style scoped>
</style>